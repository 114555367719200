import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import AppContext from "./appContext";
import MainRouter from "./MainRouter/MainRouter";
import { isAuth, removeToken } from "./utils/helperFunctions";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuth());
  const [loggedInUser, setLoggedInUser] = useState({
    loading: false,
    data: null,
  });

  const logout = () => {
    removeToken();
    setIsLoggedIn(false);
    setLoggedInUser({
      loading: false,
      data: null,
    });
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          isLoggedIn,
          loggedInUser,
        },
        setIsLoggedIn,
        logout,
      }}
    >
      <MainRouter />
      <ToastContainer />
    </AppContext.Provider>
  );
}

export default App;
