import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import AppContext from '../../appContext';
import linkImg from "../../../src/Assets/images/channelDefault/link.png"
import { confirm } from "react-confirm-box";
import { deleteInstanceApi, getAllInstances } from '../../apis';
import { Link } from 'react-router-dom';
import { Loader } from '../../Components/common/loader';
import moment from 'moment';


const Index = () => {

   const appContext = useContext(AppContext);
   const { logout } = appContext

   const [instances, setInstances] = useState({
      search: "",
      data: null,
      loading: false,
   });

   const fetchInstanceList = async (search) => {
      try {
         setInstances((v) => ({
            ...v,
            loading: true,
            data: null,
         }));

         const res = await getAllInstances();

         //   const res1 = await getDashboardDataApi(search);

         if (res.status == 200) {
            setInstances((v) => ({
               ...v,
               loading: false,
               data: res.data.data,
            }));
         } else {
            setInstances((v) => ({
               ...v,
               loading: false,
            }));
            toast.error("Something went wrong.", { toastId: "err01" });
         }

         //   if (res1.status == 200) {
         //     setDashboardData((v) => ({
         //       ...v,
         //       loading: false,
         //       data: res1.data.data,
         //     }));
         //   } else {
         //     setDashboardData((v) => ({
         //       ...v,
         //       loading: false,
         //     }));
         //     toast.error("Something went wrong.", { toastId: "err01" });
         //   }
      } catch (error) {
         if (error.response.status == 401) {
            logout();
         }
         const message =
            error.response?.data?.message || error.response.statusText;
         toast.error(message, { toastId: "err01" });
         setInstances((v) => ({
            ...v,
            loading: false,
         }));
      }
   };

   useEffect(() => {
      fetchInstanceList();
   }, []);

   const options = {
      labels: {
         confirmable: "Confirm",
         cancellable: "Cancel",
      },
   };

   const handleDelete = async (val) => {
      const result = await confirm("Are you sure ?", options);
      if (result) {
         const del = await deleteInstanceApi(val?.id);
         await fetchInstanceList("");
      } else {
         return false;
      }
   };

   // const instanceArr = instances.data?.[0].concat(instances.data?.[1])

   // console.log(instanceArr, "<<<<<<<<<<<<<<<<<<<<<<<<<<,,")

   return (
      <div className="container px-4 px-lg-2 p-4">
         <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
               <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
                  <div className="mb-2 mb-lg-0">
                     <h1 className="mb-1 h2 fw-bold">
                        All Instances
                        <span className="fs-5 text-muted ms-1">
                           {/* ({users?.length || 0}) */}
                        </span>
                     </h1>
                     {/* Breadcrumb  */}
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item">
                              <Link to="/">Dashboard</Link>
                           </li>
                           <li className="breadcrumb-item active" aria-current="page">
                              Instances
                           </li>
                        </ol>
                     </nav>
                  </div>
                  <div></div>
               </div>
            </div>
         </div>

         {instances.loading ? (
            <div className="text-center p-4">
               <Loader />
            </div>
         ) : <div> <div className="mb-3">
            <Link to="/create-instance">
               <button className="btn btn-primary btn-lg">Create Instance</button>
            </Link>
         </div> <div className="row">
               <div className="col-lg-12 col-md-12 col-12">
                  <div className="row userTableInfo ">
                     <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="card mb-4">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                                 <div>
                                    <span className="fs-6 text-uppercase fw-semi-bold">
                                       Instance
                                    </span>
                                 </div>
                                 <div>
                                    <span className="fe fe-book fs-3 text-primary" />
                                 </div>
                              </div>
                              <h2 className="fw-bold mb-1">
                                 {/* {dashboardData?.data?.totalUsersCount || 0} */}
                                 {instances?.data ? instances?.data?.length : "0"}
                              </h2>
                           </div>
                        </div>
                     </div>

                     {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Active Tests
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.activeUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}

                     {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Inactive Tests
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.deactiveUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}
                  </div>
                  {/* tab pane */}
                  <div className="">
                     {/* card */}
                     <div className="card">
                        {/* card header */}
                        <div className="card-header">
                           {/* <input
                    type="search"
                    className="form-control"
                    value={testList.search}
                    onChange={handleSearch}
                    placeholder="Search user by email"
                  /> */}
                        </div>
                        {/* table */}
                        <div className="table-responsive min-h-500px">
                           {instances?.data?.length ? (
                              <table className="table mb-0 text-nowrap">
                                 <thead className="table-light">
                                    <tr>
                                       <th scope="col" className="border-0">
                                          S.No
                                       </th>
                                       <th scope="col" className="border-0">
                                          Instance Name
                                       </th>
                                       <th scope="col" className="border-0">
                                          Assigned To
                                       </th>
                                       <th></th>

                                       <th scope="col" className="border-0">
                                          Assigned From
                                       </th>
                                       <th scope="col" className="border-0">
                                          Assigned Till
                                       </th>
                                       {/* <th scope="col" className="border-0">
                            Active
                          </th> */}

                                       <th scope="col" className="border-0" />
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {
                                       instances?.data?.length > 0 && instances?.data.map((el, i) => {
                                          // console.log(el, "pairedddddddddddddddddd")
                                          return (
                                             <tr id="table" key={`page-${i + 1}`}>
                                                <td className="align-middle border-top-0">
                                                   <div className="d-flex align-items-center">
                                                      {/* <h5 className="mb-0"> */}

                                                      {`${i + 1}`}
                                                      {/* </h5> */}
                                                   </div>
                                                </td>
                                                <td className="align-middle border-top-0">
                                                   {el?.instance || "N/A"}
                                                </td>
                                                <td className="align-middle border-top-0">
                                                   {el.isPaired ? el?.candidateId?.firstName +" "+ el?.candidateId?.lastName || "" : <i>Not assigned</i>}
                                                </td>
                                                <td>{el.isPaired ? <img src={linkImg} alt="" className='text-xs text-center' style={{ transform: "scale(0.7)" }} /> : null}</td>
                                                <td className="align-middle border-top-0">
                                                   {el.isPaired ? moment(el?.testStartTime).format("DD MMM YYYY hh:mm:ss a") : '-'}
                                                </td>
                                                <td className="align-middle border-top-0">
                                                   {el.isPaired ? moment(el?.testEndTime).format("DD MMM YYYY hh:mm:ss a") : '-'}

                                                </td>



                                                <td className="text-muted px-4 py-3 align-middle border-top-0">
                                                   {
                                                      el?.isPaired
                                                         ?
                                                         (
                                                            <span className="dropdown dropstart">
                                                               <a
                                                                  className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                                                  href="#"
                                                                  role="button"
                                                                  id="courseDropdown"
                                                                  data-bs-toggle="dropdown"
                                                                  data-bs-offset="0,0"
                                                                  aria-expanded="false"
                                                               >
                                                                  <i className="fe fe-more-vertical" />
                                                               </a>
                                                               <span
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="courseDropdown"
                                                               >
                                                                  <span className="dropdown-header">
                                                                     Settings
                                                                  </span>

                                                                  <a
                                                                     href="#"
                                                                     className="dropdown-item"
                                                                     onClick={() => handleDelete(el)}
                                                                  >
                                                                     <i className="fe fe-trash dropdown-item-icon" />
                                                                     Delete
                                                                  </a>
                                                               </span>
                                                            </span>
                                                         )
                                                         :
                                                         (
                                                            <span className="dropdown dropstart">
                                                               <a
                                                                  className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                                                  href="#"
                                                                  role="button"
                                                                  id="courseDropdown"
                                                                  data-bs-toggle="dropdown"
                                                                  data-bs-offset="0,0"
                                                                  aria-expanded="false"
                                                               >
                                                                  <i className="fe fe-more-vertical" />
                                                               </a>
                                                               <span
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="courseDropdown"
                                                               >
                                                                  <span className="dropdown-header">
                                                                     Settings
                                                                  </span>
                                                                  {/* {console.log(el, "elelelelelelelelelelelel")} */}
                                                                  <Link
                                                                     to="/create-instance"
                                                                     state={el}
                                                                     className="dropdown-item"

                                                                  // onClick={() => setSelectedPage(el)}
                                                                  >
                                                                     <i className="fe fe-edit dropdown-item-icon" />
                                                                     Edit
                                                                  </Link>
                                                                  <a
                                                                     href="#"
                                                                     className="dropdown-item"
                                                                     onClick={() => handleDelete(el)}
                                                                  >
                                                                     <i className="fe fe-trash dropdown-item-icon" />
                                                                     Delete
                                                                  </a>
                                                               </span>
                                                            </span>
                                                         )
                                                   }
                                                </td>
                                             </tr>
                                          );
                                       })
                                    }
                                 </tbody>
                              </table>
                           ) : (
                              <div className="text-center p-4">No data found</div>
                           )}
                        </div>
                     </div>
                  </div>
               </div></div>
         </div>}
      </div>
   )
}

export default Index