import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getApi } from "../apis/api-interface";
import { isAuth, removeToken } from "../utils/helperFunctions";

export default function PrivateRoute({ children }) {
  // console.log("we are in the private route");
  const checktoken = () => {

    getApi("/users/isValidToken").then((res) => {
      return children

    }).catch((err) => {
      removeToken()

      window.location.href = '/sign-in';

    });
  }
  useEffect(() => {
    checktoken()
  }, [])
  const auth = isAuth();
  console.log(auth, 'auth')
  return auth ? children : <Navigate to="/sign-in" />;

  // return children
}