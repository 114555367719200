import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePageApi,
  getAllPagesApi,
  updatePageApi,
} from "../../apis";
import { getApi } from "../../apis/api-interface";
import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import { confirm } from "react-confirm-box";


export default function UsersList() {


  const appContext = useContext(AppContext);
  const { logout } = appContext;

  const [pageList, setPageList] = useState({
    search: "",
    data: null,
    loading: false,
  });

  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });


  const [selectedPage, setSelectedPage] = useState(null);






  const fetchpageList = async (search) => {
    try {
      setPageList((v) => ({
        ...v,
        loading: true,
        data: null,
      }));

      const res = await getAllPagesApi(search);

      //   const res1 = await getDashboardDataApi(search);

      if (res.status == 200) {
        setPageList((v) => ({
          ...v,
          loading: false,
          data: res.data.data,
        }));
        // console.log(pageList.data);
      } else {
        setPageList((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }

      //   if (res1.status == 200) {
      //     setDashboardData((v) => ({
      //       ...v,
      //       loading: false,
      //       data: res1.data.data,
      //     }));
      //   } else {
      //     setDashboardData((v) => ({
      //       ...v,
      //       loading: false,
      //     }));
      //     toast.error("Something went wrong.", { toastId: "err01" });
      //   }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setPageList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };
  const fetchUserCounts = async () => {
    const response = await getApi("/users/count");
    setDashboardData((v) => ({
      ...v,
      loading: false,
      data: response.data,
    }));
    // console.log(response.data, "11111111111111111111111111111111");
    // console.log(dashboardData?.data?.totalActiveCandidates, "2222222222222222222222");
  };

  useEffect(() => {
    fetchUserCounts()
  }, [])

  useEffect(() => {
    fetchpageList(pageList.search);

  }, [pageList.search]);


  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  const handleDelete = async (val) => {
    const result = await confirm("Are you sure ?", options);
    if (result) {
      const del = await deletePageApi({ slug: val?.slug, isActive: false });
      // console.log(del)
      await fetchpageList("");
    } else {
      return false;
    }
  };




  return (
    <>
      <div className="container px-4 px-lg-2 p-4">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Pages
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Pages
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
       
       {pageList.loading ? (
                    <div className="text-center p-4">
                      <Loader />
                    </div>
                  ) :  <div className="row">
                     <div className="mb-3">
          <Link to='/pages/create-page'>

            <button className="btn btn-primary btn-lg">Create Page</button>
          </Link>
        </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="row userTableInfo ">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Pages
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-users fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.totalActivePages || 0}
                    </h2>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Active Pages
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.activeUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Inactive Pages
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.deactiveUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}
            </div>
            {/* tab pane */}
            <div className="">
              {/* card */}
              <div className="card">
                {/* card header */}
                <div className="card-header">
                  {/* <input
                    type="search"
                    className="form-control"
                    value={pageList.search}
                    onChange={handleSearch}
                    placeholder="Search user by email"
                  /> */}
                </div>
                {/* table */}
                <div className="table-responsive min-h-500px">
                  {pageList?.data?.length ? (
                    <table className="table mb-0 text-nowrap">
                      <thead className="table-light">
                        <tr>

                          <th scope="col" className="border-0">
                            S.No
                          </th>
                          <th scope="col" className="border-0">
                            Name
                          </th>
                          <th scope="col" className="border-0">
                            Slug
                          </th>
                          {/* <th scope="col" className="border-0">
                            Active
                          </th> */}

                          <th scope="col" className="border-0" />
                        </tr>
                      </thead>
                      <tbody >
                        {pageList?.data?.map((el, i) => {
                          return (

                            <tr id='table' key={`page-${i + 1}`} >
                              <td className="align-middle border-top-0">
                                <div className="d-flex align-items-center">
                                  {/* <h5 className="mb-0"> */}

                                  {`${i + 1}`}
                                  {/* </h5> */}

                                </div>
                              </td>
                              <td className="align-middle border-top-0">

                                {el?.name}

                              </td>
                              <td className="align-middle border-top-0">

                                {el?.slug}

                              </td>
                              {/* <td className="align-middle border-top-0 mb-0">
                                
                                  {el.isActive == true ?
                                   (
                                    <p className="green_flag"> Active</p>
                                  ) : (
                                    <p className="red_flag"> Inactive</p>
                                  )}
                                
                              </td> */}


                              <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Settings
                                    </span>
                                    <Link
                                      to='/pages/edit-page'
                                      state={el}
                                      className="dropdown-item"

                                    // onClick={() => setSelectedPage(el)}

                                    >
                                      <i className="fe fe-edit dropdown-item-icon" />
                                      Edit
                                    </Link>
                                    <a
                                      href="#"
                                      className="dropdown-item"

                                      onClick={() => handleDelete(el)}
                                    >
                                      <i className="fe fe-trash dropdown-item-icon" />
                                      Delete
                                    </a>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                        }
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center p-4">No data found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>

    </>
  );
}
