import React, { useState } from 'react'
import TestIndex from './TestIndex'

const CreateTestPage = () => {
  const [htmlValue, setHtmlValue] = useState('')
  const [testDetails, setTestDetails] = useState([{ title: "", description: "" }])
  const [initial, setInitial] = useState({ name: '', summay: '' })
  const [duration, setDuration] = useState('60')


  const initialValues = {
    name: '',
    summary: '',
    // description:'',
    // bannerImage:'',
    duration: '',
    numberofDetails: '',
    details: []
  };

  function onSubmit(fields) {
    console.log("sdlfjdslj")
    // display form field values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
    console.log(fields)
  }

  return (
    <div>
      <TestIndex initialValues={initialValues} onSubmit={onSubmit} htmlValue={htmlValue} setHtmlValue={setHtmlValue} type='Create' el={''} testDetails={testDetails} setTestDetails={setTestDetails} initial={initial} setInitial={setInitial} duration={duration} setDuration={setDuration} />
    </div>
  )
}

export default CreateTestPage
