import React from "react";

const CandidateDetails = (props) => {
  const { el } = props;

  return (
    <div
      className="modal fade"
      id="candidateDetail"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Candidate Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <table className="table">
                <tbody>
                <tr>
                  <th>Candidate Name</th>
                  <td>
                    {el?.accountDetails?.[0]?.firstName +
                      " " +
                      el?.accountDetails?.[0]?.lastName}
                  </td>
                </tr>
                <tr>
                  <th>Registered Email</th>
                  <td>{el?.email}</td>
                </tr>
                <tr>
                  <th>Phone number</th>
                  <td>{el?.accountDetails?.[0]?.phone}</td>
                </tr>
                <tr>
                  <th>Speciality</th>
                  <td>{el?.speciality}</td>
                </tr>
                <tr>
                  <th>skills</th>
                  {el?.accountDetails?.[0]?.skills.map((item) => (
                    <td>{item} </td>
                  ))}
                </tr>
                <tr>
                  <th>HackerRank</th>
                  <td>{el?.accountDetails?.[0]?.hackerRank}</td>
                </tr>
                <tr>
                  <th>LeetCode</th>
                  <td>{el?.accountDetails?.[0]?.leetCode}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{el?.accountDetails?.[0]?.state}</td>
                </tr>
                <tr>
                  <th>Time Zone</th>
                  <td>{el?.timeZone}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default CandidateDetails;
