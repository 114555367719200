import "./profile-review.scss";

import React, { useContext, useEffect, useState } from "react";
import {
  deleteTestApi,
  getAllCandidatesForProfileReview,
  getAllTestApi,
  updateCandidateStatusApi,
  updateProfileStatus,
} from "../../apis";

import AppContext from "../../appContext";
import Button from "react-bootstrap/Button";
import CandidateDetails from "./CandidateDetails";
import { Link } from "react-router-dom";
import { Loader } from "../../Components/common/loader";
import { confirm } from "react-confirm-box";
import { getApi } from "../../apis/api-interface";
import { toast } from "react-toastify";

const CandidatesApplied = () => {
  const appContext = useContext(AppContext);
  const { logout } = appContext;

  const [selectedCandidate, setSelectedCandidate] = useState({});

  const [candidatesList, setCandidatesList] = useState({
    search: "",
    data: null,
    loading: false,
  });

  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });

  const fetchUserCounts = async () => {
    const response = await getApi("/users/count");
    setDashboardData((v) => ({
      ...v,
      loading: false,
      data: response.data,
    }));
  };

  useEffect(() => {
    fetchUserCounts();
  }, []);
  // console.log(candidatesList.data)

  const fetchAppliedCandidatesList = async (search) => {
    try {
      setCandidatesList((v) => ({
        ...v,
        loading: true,
        data: null,
      }));

      const res = await getAllCandidatesForProfileReview(search);

      if (res.status == 200) {
        setCandidatesList((v) => ({
          ...v,
          loading: false,
          data: res.data.data?.sort(
            (date1, date2) =>
              new Date(date2.createdAt) - new Date(date1.createdAt)
          ),
        }));
      } else {
        setCandidatesList((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }

      //   if (res1.status == 200) {
      //     setDashboardData((v) => ({
      //       ...v,
      //       loading: false,
      //       data: res1.data.data,
      //     }));
      //   } else {
      //     setDashboardData((v) => ({
      //       ...v,
      //       loading: false,
      //     }));
      //     toast.error("Something went wrong.", { toastId: "err01" });
      //   }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setCandidatesList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchAppliedCandidatesList(candidatesList.search);
  }, [candidatesList.search]);

  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  const handleDelete = async (val) => {
    const result = await confirm("Are you sure ?", options);
    if (result) {
      const del = await deleteTestApi({ id: val.id, isActive: false });
      await fetchAppliedCandidatesList("");
    } else {
      return false;
    }
  };

  const updateStatus = async (id, status) => {
    try {
      const response = await updateProfileStatus({ id: id, isProfile: status });
      if (response?.status == 200) {
        await fetchAppliedCandidatesList("");
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="container px-4 px-lg-2 p-4 profileReview">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Review Profiles
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Review Prolies
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {/* <div className="mb-3">
          <Link to='/test/create-test'>

          <button className="btn btn-primary btn-lg">Create Test</button>
          </Link>
        </div> */}
        {candidatesList.loading ? (
          <div className="text-center p-4">
            <Loader />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row userTableInfo ">
                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Profiles
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-users fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {/* {dashboardData?.data?.totalUsersCount || 0} */}
                {/* {candidatesList?.data?.length || 0} */}
                {/* </h2>
                  </div>
                </div>
              </div> */}

                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Approved Profile
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.activeUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}

                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Rejected Profile
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.deactiveUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}

                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Pending Profile
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-user fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {candidatesList.data ? candidatesList.data.length : 0}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab pane */}
              <div className="">
                {/* card */}
                <div className="card">
                  {/* card header */}
                  <div className="card-header">
                    {/* <input
                    type="search"
                    className="form-control"
                    value={candidatesList.search}
                    onChange={handleSearch}
                    placeholder="Search user by email"
                  /> */}
                  </div>
                  {/* table */}
                  <div className="table-responsive min-h-500px">
                    {candidatesList?.data?.length ? (
                      <table className="table mb-0 text-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className="border-0">
                              S.No
                            </th>
                            {/* <th scope="col" className="border-0">
                            Candidate Name
                          </th> */}
                            <th scope="col" className="border-0">
                              Registered Email
                            </th>
                            {/* <th scope="col" className="border-0">
                            Test Name
                          </th> */}
                            <th scope="col" className="border-0">
                              Active
                            </th>
                            {/* <th scope="col" className="border-0">
                            Status
                          </th> */}
                            <th>Profile</th>
                            <th scope="col" className="border-0">
                              Set Status
                            </th>
                            {/* <th scope="col" className="border-0" /> */}
                          </tr>
                        </thead>
                        <tbody>
                          {candidatesList?.data?.map((el, i) => {
                            return (
                              <tr id="table" key={`page-${i + 1}`}>
                                <td className="align-middle border-top-0">
                                  <div className="d-flex align-items-center">
                                    {/* <h5 className="mb-0"> */}

                                    {`${i + 1}`}
                                    {/* </h5> */}
                                  </div>
                                </td>
                                {/* <td className="align-middle border-top-0">
                                
                                {el?.firstName +" "+ el?.lastName}
                               
                              </td> */}
                                <td
                                  className="align-middle border-top-0"
                                  onClick={() => setSelectedCandidate(el)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#candidateDetail"
                                >
                                  {el?.email}
                                </td>
                                {/* <td className="align-middle border-top-0">
                               
                               
                               {el?.testTitle}
                               
                              </td> */}
                                {/* <td className="align-middle border-top-0 mb-0">
                                
                                  {el.isActive == true ?
                                   (
                                    <p className="green_flag"> Active</p>
                                  ) : (
                                    <p className="red_flag"> Inactive</p>
                                  )}
                                
                              </td> */}
                                <td className="align-middle border-top-0 mb-0">
                                  {el?.isProfile.toLowerCase() == "approved" ? (
                                    <p className="green_flag">Approved</p>
                                  ) : el?.isProfile.toLowerCase() ==
                                    "rejected" ? (
                                    <p className="red_flag">Rejected</p>
                                  ) : (
                                    <p className="yellow_flag"> Pending</p>
                                  )}
                                </td>
                                <td className="text-muted px-4 py-3 align-middle border-top-0">
                                  <Button
                                    className="view"
                                    onClick={() => setSelectedCandidate(el)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#candidateDetail"
                                  >
                                    View
                                  </Button>
                                </td>

                                <td className="text-muted px-4 py-3 align-middle border-top-0">
                                  {el?.isProfile == "pending" ? (
                                    <span className="dropdown dropstart">
                                      <a
                                        className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                        href="#"
                                        role="button"
                                        id="courseDropdown"
                                        data-bs-toggle="dropdown"
                                        data-bs-offset="0,0"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <span
                                        className="dropdown-menu"
                                        aria-labelledby="courseDropdown"
                                      >
                                        <span className="dropdown-header">
                                          Perform Action
                                        </span>

                                        <a
                                          state={el}
                                          className="dropdown-item"
                                          onClick={() =>
                                            updateStatus(el.id, "approval")
                                          }
                                        >
                                          <i className="fe fe-check dropdown-item-icon text-success" />
                                          Approve
                                        </a>
                                        <a
                                          state={el}
                                          className="dropdown-item"
                                          onClick={() =>
                                            updateStatus(el.id, "rejected")
                                          }
                                        >
                                          <i className="fe fe-x dropdown-item-icon text-danger" />
                                          Reject
                                        </a>
                                      </span>
                                    </span>
                                  ) : null}
                                </td>
                                {/* <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Settings
                                    </span>
                                    
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                     
                                      onClick={() => handleDelete(el)}
                                    >
                                      <i className="fe fe-trash dropdown-item-icon" />
                                      Delete
                                    </a>
                                  </span>
                                </span>
                              </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center p-4">No data found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedCandidate && <CandidateDetails el={selectedCandidate} />}
    </>
  );
};

export default CandidatesApplied;
