import axios from "axios";
import { REACT_APP_API_BASE_URL } from "../utils/baseurl";

export const postApiWithoutToken = (path, data) => {
	return axios.post(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export const putApiWithoutToken = (path, data) => {
	return axios.put(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export const getApiWithoutToken = (path) => {
	return axios.get(REACT_APP_API_BASE_URL + path, {
		headers: {
			"Content-Type": "application/json",
		},
	});
};

export const postApi = (path, data) => {

	const token = localStorage.getItem("token");

	return axios.post(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getApi = (path) => {
	// console.log(REACT_APP_API_BASE_URL);
	const token = localStorage.getItem("token");
	return axios.get(REACT_APP_API_BASE_URL + path, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getApiInstance = (path) => {
	// console.log(REACT_APP_API_BASE_URL);
	const token = localStorage.getItem("token");
	// const token = `CI6MSwiaWF0IjoxNjc1MDU0OTEwLCJleHAiOjE2NzUwODM3MTB`
	return axios.get(REACT_APP_API_BASE_URL + path, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteApi = (path, data) => {
	const token = localStorage.getItem("token");
	return axios.delete(REACT_APP_API_BASE_URL + path, {
		data: data,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		}
	});
};

export const putApi = (path, data) => {
	const token = localStorage.getItem("token");

	return axios.put(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const putInstanceApi = (path, data) => {
	// const token = `CI6MSwiaWF0IjoxNjc1MDU0OTEwLCJleHAiOjE2NzUwODM3MTB`
	const token = localStorage.getItem("token");

	return axios.put(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const vdoUpdateApi = (path, data) => {
	const token = localStorage.getItem("token");

	return axios.post(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};

export const fileUpload = (path, data) => {
	const token = localStorage.getItem("token");

	return axios.post(REACT_APP_API_BASE_URL + path, data, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
};
