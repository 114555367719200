import React, { useContext } from "react";

import AppContext from "../../appContext";
import { Link } from "react-router-dom";
import { isAuth } from "../../utils/helperFunctions";
import { removeToken } from "../../utils/helperFunctions";

export default function Header() {
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;

  const handleLogout = () => {
    removeToken();
    setIsLoggedIn(false);
  };

  return (
    <>
      <header
        className={`header navbar navbar-expand-lg navbar-light  border-bottom border-light shadow-sm bg-light  navbar-sticky header_wrapper`}
      >
        <div className="container px-3">
          <Link
            to="/"
            className="navbar-brand pe-3"
            style={{ fontWeight: "800", color: "#131022" }}
          >
            <img
              src="/Visceral-Black.svg"
              width={150}
              alt="Visceral"
              className=" rounded-3 position-relative me-2"
            />
            {/* Visceral */}
          </Link>

          {isAuth() && (
            <Link
              to="/sign-in"
              className="btn btn-primary btn-sm fs-sm rounded get_hired d-lg-inline-flex"
              rel="noopener"
              onClick={handleLogout}
            >
              Sign Out
            </Link>
          )}
        </div>
      </header>
    </>
  );
}
