import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginApi } from "../../apis";
import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import { InputErrorMessage } from "../../Components/errorMessages.js";
import { setToken } from "../../utils/helperFunctions";
import { signinValidationSchema } from "./validation";

export default function SignIn() {
  const focusField = useRef(null);
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const { isLoggedIn } = appContext.state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signinValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await loginApi(values);
        console.log(res)
        if (res.data.status === 1) {
          const token = res.data.token;
          setToken(token);
          setIsLoggedIn(true);
        } else {
          toast.error("Something went wrong.", { toastId: "err01" });
        }
        setLoading(false);
      } catch (error) {
        const message =
          error.response?.data?.message || error.response.statusText;
        toast.error(message, { toastId: "err01" });
        setLoading(false);
      }
    },
  });

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  useEffect(() => {
    if (focusField.current) {
      focusField.current.focus();
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);
  return (
    <div className="container d-flex flex-column"> 
      <div className="row align-items-center justify-content-center g-0 min-vh-100">
        <div className="col-lg-5 col-md-8 py-8 py-xl-0">
          <div className="card shadow ">
            <div className="card-body p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Admin Login</h1>
                <span>
                  {/* Don’t have an account?{" "}
                    <a href="sign-up.html" className="ms-1">
                        Sign up
                    </a> */}
                </span>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Username or email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    disabled={loading}
                    placeholder="Email address here"
                    value={formik.values.email}
                    ref={focusField}
                    onChange={handleChange}
                    className={`form-control form-control-solid ${
                      formik.touched.email && Boolean(formik.errors.email)
                        ? "error"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={formik.touched.email && formik.errors.email}
                    marginBottom={-15}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    onChange={handleChange}
                    disabled={loading}
                    className={`form-control form-control-solid ${
                      formik.touched.password && Boolean(formik.errors.password)
                        ? "error"
                        : ""
                    }`}
                    name="password"
                    placeholder="**************"
                    value={formik.values.password}
                  />
                  <InputErrorMessage
                    error={formik.touched.password && formik.errors.password}
                    marginBottom={-15}
                  />
                </div>
                {/* <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="form-check">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberme"
                        />
                        <label className="form-check-label " htmlFor="rememberme">
                        Remember me
                        </label>
                    </div>
                    <div>
                        <a href="forget-password.html">Forgot your password?</a>
                    </div>
                    </div> */}
                <div>
                  <div className="d-grid">
                    {loading ? (
                      <button disabled={true} className="btn btn-primary">
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        id="kt_sign_in_submit"
                        className="btn btn-primary "
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
