import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Dashboard from "../Pages/Dashboard/index";
import Users from "../Pages/Users/index";
import Companies from '../Pages/Companies/index'
import SignIn from "../Pages/Sign-in/index";
import ForgotPassword from "../Pages/Forgot-password/index";
import PrivateRoute from "./privateRoute";
import UserDetails from "../Pages/Users/userDetails";
import Pages from "../Pages/Page/index"
import CreatePage from "../Pages/Page/CreatePage";
import EditPage from "../Pages/Page/EditPage";
import ViewPage from "../Pages/Page/ViewPage";
import TestDashboard from '../Pages/Test/index'
import CreateTest from "../Pages/Test/CreateTestPage";
import EditTest from "../Pages/Test/UpdateTestPage";
// import CreateTest2 from "../Pages/Test/TestIndex";
import AppliedDashboard from '../Pages/Applied/index'
import ProfileReview from '../Pages/Profile Review/index'
import Navbar from "../Components/Header/Navbar";
import RegisteredUsers from "../Pages/RegisteredUsers";
import Instances from "../Pages/Instances"
import InstanceForm from "../Pages/Instances/InstanceForm"
import TestScore from "../Pages/Test-score";
import ViewTestDetails from "../Pages/Test-score/ViewTestDetails";


function MainRouter() {
  return (
    <BrowserRouter>
      <Header />
      {
        localStorage.getItem('token') &&
        <Navbar />
      }

      <Routes>

        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
        <Route path="/user/details/:userId" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
        <Route path="/pages" element={<PrivateRoute><Pages /></PrivateRoute>} />
        <Route path="/pages/create-page" element={<PrivateRoute><CreatePage /></PrivateRoute>} />
        <Route path="/pages/edit-page" element={<PrivateRoute><EditPage /></PrivateRoute>} />
        <Route path="/test/create-test" element={<PrivateRoute><CreateTest /></PrivateRoute>} />
        {/* <Route path="/test/create-test2" element = {<PrivateRoute><CreateTest2/></PrivateRoute>}/> */}
        <Route path="/test/edit-test" element={<PrivateRoute><EditTest /></PrivateRoute>} />
        <Route path='/view-page' element={<PrivateRoute><ViewPage /></PrivateRoute>} />
        <Route path='/test' element={<PrivateRoute><TestDashboard /></PrivateRoute>} />
        <Route path='/applied-candidates' element={<PrivateRoute><AppliedDashboard /></PrivateRoute>} />
        <Route path='/profile-review' element={<PrivateRoute><ProfileReview /></PrivateRoute>} />
        <Route path="/registered-user" element={<PrivateRoute><RegisteredUsers /></PrivateRoute>} />
        <Route path="/instances" element={<PrivateRoute><Instances /></PrivateRoute>} />
        <Route path="/create-instance" element={<PrivateRoute><InstanceForm /></PrivateRoute>} />
        <Route path="/test-score" element={<PrivateRoute><TestScore /></PrivateRoute>} />
        <Route path="/view-test-details/:candidateId/:testId" element={<PrivateRoute><ViewTestDetails /></PrivateRoute>} />



        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export default MainRouter;
