import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import TestIndex from './TestIndex'

const UpdateTestPage = () => {

  const [htmlValue, setHtmlValue] = useState(undefined)

  const [testDetails, setTestDetails] = useState([{ title: "", description: "" }])
  const [initial, setInitial] = useState({ name: '', summary: '' })
  const [duration, setDuration] = useState(0)

  const location = useLocation()

  console.log(location?.state?.bannerImage, "LLL")

  useEffect(() => {
    setHtmlValue(location.state.description)
    setTestDetails(location.state.testDetails)
    setDuration(location.state.duration)
    setInitial({ name: location.state.title, summary: location.state.summary })
  }, [location.state])

  // console.log(location.state,location.state.testDetails.length)


  const initialValues = {
    name: location.state.title,
    summary: location.state.summary,
    // description:'',
    // bannerImage:'',
    duration: location.state.duration,
    numberofDetails: location.state.testDetails.length,
    details: location.state.testDetails
  };

  function onSubmit(fields) {
    console.log("sdlfjdslj")
    // display form field values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
    console.log(fields)
  }

  return (
    <div>
      <TestIndex initialValues={initialValues} onSubmit={onSubmit} htmlValue={htmlValue} setHtmlValue={setHtmlValue} type='Edit' el={location.state} testDetails={testDetails} setTestDetails={setTestDetails} initial={initial} setInitial={setInitial} duration={duration} setDuration={setDuration} />
    </div>
  )
}

export default UpdateTestPage
