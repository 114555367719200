import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteTestApi,
  getAllCandidatesAppliedApi,
  getAllTestApi,
  getAllTestResultsApi,
  updateCandidateStatusApi,
} from "../../apis";
import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import { confirm } from "react-confirm-box";
import { getApi } from "../../apis/api-interface";

// import CandidateDetails from './CandidateDetails';

const TestScore = () => {
  const appContext = useContext(AppContext);
  const { logout } = appContext;

  const [testResult, setTestResults] = useState({
    search: "",
    data: null,
    loading: false,
  });

  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });

  const [id, setId] = useState({ candidateId: "", testId: "" })

  const navigate = useNavigate()

  const fetchUserCounts = async () => {
    const response = await getApi("/users/count");
    setDashboardData((v) => ({
      ...v,
      loading: false,
      data: response.data,
    }));

  };

  useEffect(() => {
    fetchUserCounts()
  }, [])

  const fetchAppliedCandidatesList = async (search) => {
    try {
      setTestResults((v) => ({
        ...v,
        loading: true,
        data: null,
      }));

      const res = await getAllTestResultsApi();
      console.log(res, "getAllCandidatesAppliedApi");
      if (res.status == 200) {
        setTestResults((v) => ({
          ...v,
          loading: false,
          data: res.data.testResults,
        }));
        // console.log(res.data.data[0]);
      } else {
        setTestResults((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setTestResults((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchAppliedCandidatesList(testResult.search);
  }, [testResult.search]);

  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  const handleDelete = async (val) => {
    const result = await confirm("Are you sure ?", options);
    if (result) {
      const del = await deleteTestApi({ id: val.id, isActive: false });
      // console.log(del);
      await fetchAppliedCandidatesList("");
    } else {
      return false;
    }
  };

  // const handleViewTestDetails = (a) => {
  //   // console.log(a?.candidateId, "<><><><><.", a?.testId)
  //   // setId({ candidateId: a?.candidateId, testId: a?.testId })

  //   console.log(id, "8888888888")

  //   // const state = { id };

  //   if (a?.candidateId && a?.testId) {
  //     navigate(`/view-test-details/${a?.candidateId}/${a?.testId}`)
  //   }
  // }

  const updateStatus = async (id, email, title, status) => {
    try {
      // console.log(status);
      const response = await updateCandidateStatusApi({
        id: id,
        status: status,
        testName: title,
        email,
      });
      // console.log(response);
      if (response?.status == 200) {
        await fetchAppliedCandidatesList("");
      } else {
        toast.error("Something went wrong");
      }
      // console.log(response);
    } catch (err) {
      toast.error("Something went wrong");
    }
  };


  return (
    <>
      <div className="container px-4 px-lg-2 p-4">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Test Score
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Test Score
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {/* <div className="mb-3">
          <Link to='/test/create-test'>

          <button className="btn btn-primary btn-lg">Create Test</button>
          </Link>
        </div> */}
        {testResult.loading ? (
          <div className="text-center p-4">
            <Loader />
          </div>
        ) : <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="row userTableInfo ">
              <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Tests
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-users fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">

                      {dashboardData?.data ? dashboardData?.data?.totalTests : <Loader />}
                      {/* {testResult?.data?.length} */}
                    </h2>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Approved Candidates
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data ?dashboardData?.data?.totalApprovedCandidates :<Loader/>}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Rejected Candidates
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data ?dashboardData?.data?.totalRejectedCandidates:<Loader/>}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Pending Candidates
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data ?dashboardData?.data?.totalPendingCandidates:<Loader/>}
                    </h2>
                  </div>
                </div>
              </div> */}
            </div>
            {/* tab pane */}
            <div className="">
              {/* card */}
              <div className="card">
                {/* card header */}
                <div className="card-header">
                  {/* <input
                    type="search"
                    className="form-control"
                    value={testResult.search}
                    onChange={handleSearch}
                    placeholder="Search user by email"
                  /> */}
                </div>
                {/* table */}
                <div className="table-responsive min-h-500px">
                  {testResult?.data?.length > 0 ? (
                    <table className="table mb-0 text-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className="border-0">
                            S.No
                          </th>
                          {/* <th scope="col" className="border-0">
                            Candidate Name
                          </th> */}
                          <th scope="col" className="border-0">
                            Candidate's Name
                          </th>
                          <th scope="col" className="border-0">
                            Email Id
                          </th>
                          <th scope="col" className="border-0">
                            Phone Number
                          </th>
                          <th scope="col" className="border-0">
                            Test Name
                          </th>
                          <th scope="col" className="border-0">
                            Total Tests
                          </th>
                          <th scope="col" className="border-0">
                            Total Attempted
                          </th>
                          <th scope="col" className="border-0">
                            Total Solved
                          </th>
                          <th scope="col" className="border-0">
                            Time Remaining
                          </th>
                          <th scope="col" className="border-0" />
                        </tr>
                      </thead>
                      <tbody>
                        {testResult?.data?.map((el, i) => {
                          console.log(el, "__________")
                          return (
                            <tr id="table" key={`page-${i + 1}`}>
                              {/* {console.log(el,"111111111111111111111111111111111111111111111111111111")} */}
                              <td className="align-middle border-top-0">
                                <div className="d-flex align-items-center">
                                  {/* <h5 className="mb-0"> */}

                                  {`${i + 1}`}
                                  {/* </h5> */}
                                </div>
                              </td>
                              {/* <td className="align-middle border-top-0">
                                
                                {el?.firstName +" "+ el?.lastName}
                               
                              </td> */}
                              <td className="align-middle border-top-0">
                                {
                                  (el?.firstName && el?.lastName) ?
                                    el?.firstName + " " + el?.lastName :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0">
                                {/* {el?.createdAt} */}
                                {
                                  el?.email ?
                                    el?.email :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.phoneNumber ?
                                    el?.phoneNumber :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.title ?
                                    el?.title :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.testJSON?.UserStats?.TotalTests ?
                                    el?.testJSON?.UserStats?.TotalTests
                                    :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.testJSON?.UserStats?.TotalAttempted ?
                                    el?.testJSON?.UserStats?.TotalAttempted
                                    :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.testJSON?.UserStats?.TotalSolved ?
                                    el?.testJSON?.UserStats?.TotalSolved
                                    :
                                    "N/A"
                                }
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {
                                  el?.testJSON ?
                                    (el?.testJSON?.UserStats?.TimeRemaining?.Hour && el?.testJSON?.UserStats?.TimeRemaining?.Minutes) ?
                                      el?.testJSON?.UserStats?.TimeRemaining?.Hour + " Hr : " + el?.testJSON?.UserStats?.TimeRemaining?.Minutes + " Mins"
                                      : "N/A"
                                    :
                                    "N/A"
                                }
                              </td>
                              <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Settings
                                    </span>
                                    {
                                      el?.testJSON.UserStats  ?
                                        <Link
                                          to={`/view-test-details/${el?.candidateId}/${el?.testId}`}
                                          className="dropdown-item"
                                        >
                                          <i className="bi bi-hand-index dropdown-item-icon" />
                                          View
                                        </Link> :
                                        <button
                                          disabled
                                          // to={`/view-test-details/${el?.candidateId}/${el?.testId}`}
                                          className="dropdown-item"
                                        >
                                          <i className="bi bi-hand-index dropdown-item-icon" />
                                          View
                                        </button>
                                    }
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center p-4">No data found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      {/* <CandidateDetails/> */}
    </>
  );
};

export default TestScore;
