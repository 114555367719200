import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetailsByIdApi, getVideosByUserIdApi } from "../../apis";
import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import { getLastElementId } from "../../utils/helperFunctions";


export default function UserDetails() {
	const userId = getLastElementId();
	const appContext = useContext(AppContext);
	const { logout } = appContext;
	const [userDetails, setUserDetails] = useState({
		loading: true,
		data: null,
	});

	const fetchUserDetails = async (id) => {
		try {
			setUserDetails((v) => ({
				...v,
				loading: true,
				data: null,
			}));
			const res = await getUserDetailsByIdApi(id);

			if (res.status == 200) {
				setUserDetails((v) => ({
					...v,
					loading: false,
					data: res.data.data,
				}));
			} else {
				setUserDetails((v) => ({
					...v,
					loading: false,
					// data: res.data.data,
				}));
				toast.error("Something went wrong.", { toastId: "err01" });
			}
		} catch (error) {
			if (error.response.status == 401) {
				logout();
			}
			const message = error.response?.data?.message || error.response.statusText;
			toast.error(message, { toastId: "err01" });
			setUserDetails((v) => ({
				...v,
				loading: false,
			}));
		}
	};

	

	useEffect(() => {
		fetchUserDetails(userId);
		
	}, []);

	return (
		<>
			<div className='py-14'>
				{userDetails.loading ? (
					<div className='text-center p-4'>
						<Loader />
					</div>
				) : (
					<div className='container'>
						<div className='row'>
							<div className='col-xl-12 col-md-12 '>
								<div className='d-xl-flex '>
									{/* <div className="mb-3 mb-md-0">
                    <img
                      src="../../../assets/images/job/job-brand-logo/job-list-logo-1.svg"
                      alt=""
                      className="icon-shape border rounded-circle"
                    />
                  </div> */}
									<div className='ms-xl-3 w-100 mt-3 mt-xl-0'>
										<div className='pb-4 mb-4 d-md-flex align-items-center justify-content-between'>
											<div>
												<h1 className='mb-1 h2 '>{userDetails.data?.firstName + " " + userDetails.data?.lastName}</h1>
												<div>
													<span> {userDetails.data?.email}</span>
													{/* <span className='text-dark ms-2 fw-medium'>
                            0{" "}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width={10}
                              height={10}
                              fill='currentColor'
                              className='bi bi-star-fill text-warning align-baseline'
                              viewBox='0 0 16 16'
                            >
                              <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                            </svg>
                          </span>
                          <span className='ms-0'>(0 Reviews)</span> */}
												</div>
											</div>
											<div>
												
											</div>
										</div>
										<div>
											<div className='d-md-flex justify-content-between '>
												<div className='mb-2 mb-md-0' style={{ opacity: 0 }}>
													<span className='me-2'>
														{" "}
														<i className='fe fe-briefcase text-muted' />
														<span className='ms-1 '>2 - 6 years</span>
													</span>
													<span className='me-2'>
														<i className='fe fe-dollar-sign text-muted' />
														<span className='ms-1 '>Not discloses</span>
													</span>
													<span className='me-2'>
														<i className='fe fe-map-pin text-muted' />
														<span className='ms-1 '>Surat, Gujarat</span>
													</span>
												</div>
												<div>
													<i className='fe fe-clock text-muted' />{" "}
													<span>{moment(userDetails.data?.created_at).fromNow()}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr className='my-4' />
								{/* <div className="row ">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                      <div className="mb-2 mb-lg-0">
                        <h2 className="mb-0 h2 fw-bold">Video library </h2>
                      </div>
                      <div>
                        <Link
                          to={`/user/upload-video/${userDetails.data?.id}`}
                          className="btn btn-primary me-2"
                        >
                          Upload Video
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
								{/* <div className="videos_wrapper">
                  <div className="container px-0 px-2 py-5">
                    <div className="row">
                      {userVideos.loading ? (
                        <div className="text-center">
                          <Loader />
                        </div>
                      ) : userVideos.data?.length ? (
                        userVideos.data.map((el) => {
                          return (
                            <div
                              key={`video-${el.id}`}
                              className="col-xl-4 col-lg-6 col-md-6 col-12 mb-4"
                            >
                              <div className="card card-hover ">
                                <Link to={`/video/details/${el.id}`}>
                                  <img
                                    src={
                                      el.coverImagesDetails
                                        ? process.env.REACT_APP_API_BASE_URL + el.coverImagesDetails
                                        : "/Assets/images/video-thumbnail/video-placeholder.png"
                                    }
                                    className="img-fluid  rounded-top-md"
                                    alt=""
                                  />
                                </Link>
                                <div className="card-body">
                                  {el.categoryDetails ? (
                                    <span className="badge bg-info mb-3">
                                      {el.categoryDetails?.name}
                                    </span>
                                  ) : null}
                                  <h3>
                                    <Link
                                      to={`/video/details/${el.id}`}
                                      className="text-inherit"
                                    >
                                      {el.title}
                                    </Link>
                                  </h3>
                                  <p className="para">{el.description}</p>
                                  <div className="row align-items-center g-0 mt-4"> */}
								{/* <div className="col-auto">
                          <img
                            src="../assets/images/avatar/avatar-7.jpg"
                            alt=""
                            className="rounded-circle avatar-sm me-2"
                          />
                        </div> */}
								{/* <div className="col lh-1">
                                      <h5 className="mb-1">
                                        {userDetails.data?.firstName +
                                          " " +
                                          userDetails.data?.lastName}
                                      </h5>
                                      <p className="fs-6 mb-0">
                                        {moment(el.created_at).format(
                                          "MMM DD, YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-auto">
                                      <p className="fs-6 mb-0">
                                        {moment(el.created_at).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center">No videos found</div>
                      )}
                    </div>
                  </div>
                </div> */}
								{/* <div>
                  <p>
                    <span>
                      Job Applicants: <span className="text-dark">306</span>
                    </span>
                  </p>
                </div>
                <div className="mt-6">
                  <h2 className="mb-3 fs-3">Job description</h2>
                  <p>
                    Maintains information technology strategies by managing
                    staff; researching, Budgeting and implementing technological
                    strategic solutions.
                  </p>
                </div>
                <div className="mt-6">
                  <h2 className="mb-3 fs-3">Role</h2>
                  <p>
                    Aliquam pellentesque mollis interdum. Proin ligula lacus,
                    maximus quis ante a, luctus sodales sapien. Donec ut
                    tristique nisi. Nulla a quam sit amet turpis convallis
                    porttitor vel sed quam. Ut in odio enim. Maecenas eu tellus
                    erat. Maecenas nec maximus elit, ac suscipit justo. Maecenas
                    nisl tellus, sodales non gravida eget, placerat sit amet
                    erat.
                  </p>
                </div>
                <div className="mt-6">
                  <h2 className="mb-3 fs-3">Responsibilities</h2>
                  <ul>
                    <li>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elitì
                    </li>
                    <li> Vivamus maximus sem ac pellentesque tinciduntì</li>
                    <li>
                      {" "}
                      Sed vitae metus in mauris ultricies tempor hendrerit eu
                      nisiì
                    </li>
                    <li>
                      {" "}
                      Sed in odio a lorem porttitor dictum et eget nullaì
                    </li>
                    <li>
                      {" "}
                      Donec molestie tortor sed risus hendrerit, in laoreet diam
                      tinciduntì
                    </li>
                    <li> Curabitur finibus lacus ac dui placerat venenatisì</li>
                    <li>
                      {" "}
                      Aenean id ligula molestie, pretium ipsum in, varius elit.
                    </li>
                  </ul>
                </div>
                <div className="mt-6">
                  <h2 className="mb-3 fs-3">Desired Candidate Profile</h2>
                  <ul>
                    <li>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elitì
                    </li>
                    <li>
                      {" "}
                      Minimum consulting experience of 2 years including Threat
                      Hunting role
                    </li>
                    <li>
                      {" "}
                      Sed vitae metus in mauris ultricies tempor hendrerit eu
                      nisiì
                    </li>
                    <li>
                      {" "}
                      Donec molestie tortor sed risus hendrerit, in laoreet diam
                      tinciduntì
                    </li>
                    <li>Sed in odio a lorem porttitor dictum et eget nullaì</li>
                    <li> Curabitur finibus lacus ac dui placerat venenatisì</li>
                    <li>
                      {" "}
                      Aenean id ligula molestie, pretium ipsum in, varius elit.
                    </li>
                  </ul>
                </div>
                <div className="mt-6">
                  <h2 className="mb-3 fs-3">Perks and Benefits</h2>
                  <ul>
                    <li> Health insurance</li>
                    <li> Employee discount</li>
                    <li> Relocation assistance</li>
                    <li> Cafeteri</li>
                    <li>Soft Skill Trainin</li>
                    <li> Free Transport</li>
                    <li> Education Assistance</li>
                    <li> Work From Home</li>
                  </ul>
                </div> */}
							</div>
						</div>
					</div>
				)}
			</div>

		

			{/* <CreateChannel
        channel={selectedChannel} 
        fetchUserDetails={fetchUserDetails}
        userDetails={userDetails}
      /> */}
		</>
	);
}
