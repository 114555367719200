import { REACT_APP_BUCKET_URL } from "./baseurl";

export const getToken = () => localStorage.getItem("token");
export const setToken = (token) => localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");
export const isAuth = () => Boolean(getToken());
export const getLastElementId = () => {
  const path = window.location.pathname.split("/");

  return path[path.length - 1];
};

export const getLastElementId3 = () => {
  const path = window.location.pathname.split("/");

  let obj = { videoId: path[path.length - 1], userId: path[path.length - 2] };
  return obj;
};

export const get2LastElementId = () => {
  const path = window.location.pathname.split("/");

  return path[path.length - 2];
};

export const reactSelectCustomStyle = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    boxShadow: "none",
    borderColor: state.isFocused ? "rgb(33,33,33)" : "#ced4da",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(33,33,33)" : "#ced4da",
    },
  }),
};
export const encodeURI2 = (url) => {
  if (Boolean(url.toString().startsWith("https"))) {
    return encodeURI(url);
  } else {
    return encodeURI(REACT_APP_BUCKET_URL + url);
  }
};

export const getState = (state) => {
  switch (state?.toLowerCase()) {
    case "approval":
      return <p className="green_flag">Approved</p>;
    case "rejected":
      return <p className="red_flag">Rejected</p>;
    default:
      return <p className="yellow_flag text-capitalize">{state}</p>;
  }
};
