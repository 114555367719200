import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import { getAllCandidatesForProfileReview } from "../../apis";
import { getApi } from "../../apis/api-interface";
import { getDashboardDataApi } from "../../apis";
import { toast } from "react-toastify";

export default function Dashboard() {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });
  const [profileReview, setProfileReview] = useState("");
  const navigate = useNavigate();
  const fetchUserCounts = async () => {
    const response = getApi("/users/count")
      .then((res) => {
        setDashboardData((v) => ({
          ...v,
          loading: false,
          data: res.data,
        }));
      })
      .catch((err) => {});
  };
  const fetchAppliedCandidatesList = async (search) => {
    try {
      const res = await getAllCandidatesForProfileReview(search);

      setProfileReview(res.data.data.length);
    } catch (error) {}
  };
  useEffect(() => {
    fetchUserCounts();
    fetchAppliedCandidatesList();
  }, []);

  return (
    <>
      <div className="">
        {/* Container  */}
        <div className="container px-4 px-lg-2 p-4">
          <>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
                  <div className="mb-3 mb-lg-0">
                    <h1 className="mb-0 h2 fw-bold">Dashboard</h1>
                  </div>
                  <div className="d-flex" style={{ opacity: 0 }}>
                    <div className="input-group">
                      <input
                        className="form-control flatpickr"
                        type="text"
                        placeholder="Select Date"
                        aria-describedby="basic-addon2"
                      />
                      <span
                        className="input-group-text text-muted"
                        id="basic-addon2"
                      >
                        <i className="fe fe-calendar" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {dashboardData.data ? (
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/users">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Candidates
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-users fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalApprovedProfiles || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {dashboardData?.data?.totalApprovedProfiles || 0}
                        </span>
                        <span className="ms-1 fw-medium">
                          Number of Candidates
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/companies">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Companies
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-calendar fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.companies || 0}
                        </h2>
                        <span className="text-danger fw-semi-bold">
                          {dashboardData?.data?.companies || 0}+
                        </span>
                        <span className="ms-1 fw-medium">
                          Number of companies
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/test">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Tests
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalActiveTests || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {dashboardData?.data?.totalActiveTests || 0}
                        </span>
                        <span className="ms-1 fw-medium">Create Test</span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/applied-candidates">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Test Applied Candidates
                            </span>
                          </div>
                          <div>
                            <span className=" fe fe-user fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalAppliedCandidates || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />
                          {dashboardData?.data?.totalAppliedCandidates || 0}
                        </span>
                        <span className="ms-1 fw-medium">
                          Candidates Applied
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/applied-candidates">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Test Score
                            </span>
                          </div>
                          <div>
                            <span className=" fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalAppliedCandidates || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />
                          {dashboardData?.data?.totalAppliedCandidates || 0}
                        </span>
                        <span className="ms-1 fw-medium">Candidates Score</span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/profile-review">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Profile Review
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {profileReview && profileReview}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {profileReview && profileReview}
                        </span>
                        <span className="ms-1 fw-medium">
                          Candidate Profiles
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/pages">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Pages
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalActivePages || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {dashboardData?.data?.totalActivePages || 0}
                        </span>
                        <span className="ms-1 fw-medium">Create Pages</span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/registered-user">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Registered Users
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.totalActiveCandidates || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {dashboardData?.data?.totalProfiles || 0}
                        </span>
                        <span className="ms-1 fw-medium">Registered Users</span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <Link to="/instances">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                          <div>
                            <span className="fs-6 text-uppercase fw-semi-bold">
                              Instances
                            </span>
                          </div>
                          <div>
                            <span className="fe fe-book fs-3 text-primary" />
                          </div>
                        </div>
                        <h2 className="fw-bold mb-1">
                          {dashboardData?.data?.instanceCount || 0}
                        </h2>
                        <span className="text-success fw-semi-bold">
                          <i className="fe fe-trending-up me-1" />+
                          {dashboardData?.data?.instanceCount || 0}
                        </span>
                        <span className="ms-1 fw-medium">
                          Candidate Instance
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>

                {/*  <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Number Of Channels
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-user-check fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">-</h2>
                      <span className="text-success fw-semi-bold">
                        <i className="fe fe-trending-up me-1" />-
                      </span>
                      <span className="ms-1 fw-medium">Channels</span>
                    </div>
                  </div>
                </div> */}
              </div>
            ) : (
              <div className="text-center p-7">
                <Loader />
              </div>
            )}
          </>
        </div>
        {/* <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 col-12">
              <div className="card mb-4">
                <div className="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="mb-0">Uploaded Videos</h4>
                  </div>
                  <div>
                    <div className="dropdown dropstart">
                      <a
                        className="text-muted text-decoration-none"
                        href="#"
                        role="button"
                        id="courseDropdown1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="courseDropdown1"
                      >
                        <span className="dropdown-header">Settings</span>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-external-link dropdown-item-icon " />
                          Export
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-mail dropdown-item-icon " />
                          Email Report
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-download dropdown-item-icon " />
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="earning" className="apex-charts" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="card mb-4">
                <div className="card-header align-items-center card-header-height  d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="mb-0">Join Users</h4>
                  </div>
                  <div>
                    <div className="dropdown dropstart">
                      <a
                        className="text-muted text-decoration-none"
                        href="#"
                        role="button"
                        id="courseDropdown2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fe fe-more-vertical" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="courseDropdown2"
                      >
                        <span className="dropdown-header">Settings</span>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-external-link dropdown-item-icon " />
                          Export
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-mail dropdown-item-icon " />
                          Email Report
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-download dropdown-item-icon " />
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="traffic"
                    className="apex-charts d-flex justify-content-center"
                  />
                </div>
              </div>
            </div>
          </div> */}
        {/* <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
              <div className="card h-100">
                <div
                  className="card-header d-flex align-items-center
                                justify-content-between card-header-height"
                >
                  <h4 className="mb-0">Popular Channels</h4>
                  <a href="#" className="btn btn-outline-white btn-sm">
                    View all
                  </a>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item px-0 pt-0 ">
                      <div className="row">
                        <div className="col-auto">
                          <div className="avatar avatar-md avatar-indicators avatar-offline">
                            <img
                              alt="avatar"
                              src="../../../assets/images/avatar/avatar-1.jpg"
                              className="rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col ms-n3">
                          <h4 className="mb-0 h5">Annu Dubey</h4>
                          <span className="me-2 fs-6">
                            <span className="text-dark  me-1 fw-semi-bold">
                              42
                            </span>
                            Videos
                          </span>
                          <span className="me-2 fs-6">
                            <span className="text-dark  me-1 fw-semi-bold">
                              2,000
                            </span>
                            Likes
                          </span>
                          <span className="fs-6">
                            <span className="text-dark  me-1 fw-semi-bold">
                              250
                            </span>{" "}
                            Reviews
                          </span>
                        </div>
                        <div className="col-auto">
                          <span className="dropdown dropstart">
                            <a
                              className="text-muted text-decoration-none"
                              href="#"
                              role="button"
                              id="courseDropdown7"
                              data-bs-toggle="dropdown"
                              data-bs-offset="-20,20"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </a>
                            <span
                              className="dropdown-menu"
                              aria-labelledby="courseDropdown7"
                            >
                              <span className="dropdown-header">Settings</span>
                              <a className="dropdown-item" href="#">
                                <i className="fe fe-edit dropdown-item-icon " />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="fe fe-trash dropdown-item-icon " />
                                Remove
                              </a>
                            </span>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4">
              <div className="card h-100">
                <div
                  className="card-header d-flex align-items-center
                                justify-content-between card-header-height"
                >
                  <h4 className="mb-0">Recent Videos</h4>
                  <a href="#" className="btn btn-outline-white btn-sm">
                    View all
                  </a>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item px-0 pt-0">
                      <div className="row">
                        <div className="col-auto">
                          <a href="#">
                            <img
                              src="../../../assets/images/course/course-javascript.jpg"
                              alt=""
                              className="img-fluid rounded img-4by3-lg"
                            />
                          </a>
                        </div>
                        <div className="col ps-0">
                          <a href="#">
                            <h5 className="text-primary-hover">
                              The Modern JavaScript Courses
                            </h5>
                          </a>
                          <div className="d-flex align-items-center">
                            <img
                              src="../../../assets/images/avatar/avatar-1.jpg"
                              alt=""
                              className="rounded-circle avatar-xs me-2"
                            />
                            <span className="fs-6">Annu Dubey</span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <span className="dropdown dropstart">
                            <a
                              className="text-muted text-decoration-none"
                              href="#"
                              role="button"
                              id="courseDropdown5"
                              data-bs-toggle="dropdown"
                              data-bs-offset="-20,20"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </a>
                            <span
                              className="dropdown-menu"
                              aria-labelledby="courseDropdown5"
                            >
                              <span className="dropdown-header">Settings</span>
                              <a className="dropdown-item" href="#">
                                <i className="fe fe-edit dropdown-item-icon " />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="fe fe-trash dropdown-item-icon " />
                                Remove
                              </a>
                            </span>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-12 mb-4">
              <div className="card h-100">
                <div className="card-header card-header-height d-flex align-items-center">
                  <h4 className="mb-0">Activity</h4>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush list-timeline-activity">
                    <li className="list-group-item px-0 pt-0 border-0 mb-2">
                      <div className="row">
                        <div className="col-auto">
                          <div className="avatar avatar-md avatar-indicators avatar-away">
                            <img
                              alt="avatar"
                              src="../../../assets/images/avatar/avatar-1.jpg"
                              className="rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col ms-n2">
                          <h4 className="mb-0 h5">Annu Dubey</h4>
                          <p className="mb-1">Just uploaded JS Tutorial</p>
                          <span className="fs-6 text-muted">2m ago</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
      </div>
    </>
  );
}
