import React, { useContext, useEffect, useState } from "react";
import { corporateFormListData, updateEmployer } from "../../apis";

import AppContext from "../../appContext";
import { Link } from "react-router-dom";
import { Loader } from "../../Components/common/loader";
import { getState } from "../../utils/helperFunctions";
import { toast } from "react-toastify";

export default function UsersList() {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  const [corporateList, setCorporateList] = useState({
    search: "",
    data: null,
    loading: false,
  });

  const fetchCorporateList = async (search) => {
    try {
      setCorporateList((v) => ({
        ...v,
        loading: true,
        data: null,
      }));
      const res = await corporateFormListData();

      if (res.status == 200) {
        setCorporateList((v) => ({
          ...v,
          loading: false,
          data: res.data.data,
        }));
      } else {
        setCorporateList((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setCorporateList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };
  const updateEmployerStatus = async (id, state) => {
    try {
      const { status, data } = await updateEmployer({
        uri: `/corporate/${
          state === "approval" ? "approveEmployer" : "deleteEmployer"
        }`,
        payload: { userId: id, isProfile: state },
      });
      if (status === 200) {
        if (data?.status === 1) {
          toast.success(data?.message);
          fetchCorporateList();
        } else {
          toast.error(data?.message);
        }
      } else {
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setCorporateList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchCorporateList();
  }, []);

  return (
    <>
      <div className="container px-4 px-lg-2 p-4">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Companies
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Companies
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {corporateList.loading ? (
          <div className="text-center p-4">
            <Loader />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row userTableInfo ">
                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Details
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-users fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {corporateList.data?.length || 0}
                      </h2>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Active Companies
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {/* {dashboardData?.data?.activeUsersCount || 0} *
                    </h2>
                  </div>
                </div>

              </div> */}

                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Inactive Companies
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {/* {dashboardData?.data?.deactiveUsersCount || 0} 
                    </h2>
                  </div>
                </div>

              </div> */}
              </div>
              {/* tab pane */}
              <div className="">
                {/* card */}
                <div className="card">
                  {/* card header */}
                  {/* <div className="card-header">
                  <input
                    type="search"
                    className="form-control"
                    // value={userList.search}
                    // onChange={handleSearch}
                    placeholder="Search user by email"
                  />
                </div> */}
                  {/* table */}
                  <div className="table-responsive min-h-500px">
                    {corporateList.data?.length ? (
                      <table className="table mb-0 text-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>S.NO</th>
                            <th scope="col" className="border-0">
                              Name
                            </th>
                            <th scope="col" className="border-0">
                              Company
                            </th>
                            <th scope="col" className="border-0">
                              Email
                            </th>
                            <th scope="col" className="border-0">
                              Phone
                            </th>
                            <th scope="col" className="border-0">
                              Candidate email
                            </th>
                            <th scope="col" className="border-0">
                              Candidate speciality
                            </th>
                            <th scope="col" className="border-0">
                              Candidate experience
                            </th>
                            <th scope="col" className="border-0">
                              Set Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {corporateList?.data?.map((el, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{el?.name || "-"}</td>
                                <td>{el?.company || "-"}</td>
                                <td>{el?.email || "-"}</td>
                                <td>{el?.phone || "-"}</td>
                                <td>{el?.user?.email || "-"}</td>
                                <td>{el?.user?.speciality || "-"}</td>
                                <td>
                                  {el?.user?.experience
                                    ? `${el?.user?.experience} Yrs`
                                    : "-"}
                                </td>
                                <td className="text-muted px-4 py-3 align-middle border-top-0">
                                  {el?.user?.isProfile == "pending" ? (
                                    <span className="dropdown dropstart">
                                      <a
                                        className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                        href="#"
                                        role="button"
                                        id="courseDropdown"
                                        data-bs-toggle="dropdown"
                                        data-bs-offset="0,0"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <span
                                        className="dropdown-menu"
                                        aria-labelledby="courseDropdown"
                                      >
                                        <span className="dropdown-header">
                                          Perform Action
                                        </span>

                                        <a
                                          state={el}
                                          className="dropdown-item"
                                          onClick={() =>
                                            updateEmployerStatus(
                                              el?.user?.id,
                                              "approval"
                                            )
                                          }
                                        >
                                          <i className="fe fe-check dropdown-item-icon text-success" />
                                          Approve
                                        </a>
                                        <a
                                          state={el}
                                          className="dropdown-item"
                                          onClick={() =>
                                            updateEmployerStatus(
                                              el?.user?.id,
                                              "rejected"
                                            )
                                          }
                                        >
                                          <i className="fe fe-x dropdown-item-icon text-danger" />
                                          Reject
                                        </a>
                                      </span>
                                    </span>
                                  ) : (
                                    getState(el?.user?.isProfile)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center p-4">No data found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
