import {
  deleteApi,
  getApi,
  getApiInstance,
  postApi,
  postApiWithoutToken,
  putApi,
  putInstanceApi,
} from "./api-interface";

/* User APIs */

export const addUserApi = (payload) => {
  return postApi(`/api/addUser`, payload);
};

export const getUserDetailsByIdApi = (id) => {
  return getApi(`/api/getUserById?id=${id}`);
};

export const deleteUserApi = (id) => {
  return deleteApi(`/api/deleteUser/${id}`, id);
};

export const suspendUserApi = (id) => {
  return deleteApi(`/api/suspenedUserById?id=${id}`);
};

export const activateUserApi = (id) => {
  return putApi(`/api/activateUserById?id=${id}`);
};

export const updateUserApi = (payload) => {
  return putApi(`/api/updatedUser`, payload);
};

// export const getAllUsersApi = (search = "") => {
// 	return getApi(`/api/getAllUsers?search=${search}`);
// };

export const getAllUsersApi = () => {
  return getApi("/accountDetails/getAllUsersDetailsApproved");
};

export const getAllRegisteredUsersApi = () => {
  return getApi("/accountDetails/getAllRegisteredUser");
};

/* Category APIs */

export const updateCategoryApi = (payload) => {
  return putApi(`/api/updateCategory`, payload);
};

export const deleteCategoryApi = (id) => {
  return deleteApi(`/api/deleteCategory/${id}`, id);
};

export const getAllCategoriesApi = (search) => {
  return getApi(`/api/getCategory?search=${search}`);
};

export const createCategoryApi = (payload) => {
  return postApi(`/api/category`, payload);
};

export const activeCategoryApi = (id) => {
  return putApi(`/api/activeCategory/${id}`, id);
};

/* Dashboard APIs */

export const getDashboardDataApi = () => {
  return getApi(`/api/dashboard`);
};

/* Login APIs */

export const loginApi = (payload) => {
  // console.log(payload)
  return postApiWithoutToken("/users/adminLogin", payload);
};

/* Channel APIs */

export const createChannelApi = (id) => {
  return postApi("/api/createChannel", id);
};

export const getChannelDetailsApi = (id) => {
  return getApi(`/api/getChannelById?id=${id}`);
};

export const updateChannelApi = (payload) => {
  return postApi(`/api/updateChannel`, payload);
};

/* Page APIs */
export const createPageApi = (payload) => {
  return postApi("/pages/createPages", payload);
};
export const getAllPagesApi = () => {
  return getApi("/pages/getAllPages");
};
export const deletePageApi = (payload) => {
  return putApi("/pages/deletePages", payload);
};
export const updatePageApi = (payload) => {
  return putApi("/pages/updatePages", payload);
};

/* Test APIs */
export const createTestApi = (payload) => {
  return postApi("/testDetail/createTestDetails", payload);
};
export const updateTestApi = (payload) => {
  return putApi("/testDetail/updateTestDetails", payload);
};
export const getAllTestApi = () => {
  return getApi("/testDetail/getTestDetails");
};
export const deleteTestApi = (payload) => {
  return putApi("/testDetail/deleteTestDetails", payload);
};
export const uploadBannerImageApi = (payload) => {
  return postApi("/testDetail/createTestDetailsUploadImage", payload);
};

// Applied Candidate APIs
export const getAllCandidatesAppliedApi = () => {
  return getApi("/attemptTest/allUsersforAttempedTest");
};
export const updateCandidateStatusApi = (payload) => {
  return postApi("/attemptTest/postStatus", payload);
};

//Profile for Review APIs
export const getAllCandidatesForProfileReview = () => {
  return getApi("/accountDetails/getAllUsersDetails");
};
export const updateProfileStatus = (payload) => {
  return putApi("/users/profileStatus", payload);
};

export const corporateFormListData = () => {
  return getApi("/corporate/corporateDetails");
};

// Instances API
export const createInstanceApi = (payload) => {
  return postApi(`/instance/createInstance`, payload);
};

export const getAllInstances = () => {
  return getApiInstance(`/instance/getAllInstance`);
};

export const getInstanceById = (id) => {
  return getApiInstance(`/instance/getInstanceById?=${id}`);
};

// export const deleteInstanceApi = (payload) => {
// 	return putApi('/instance/deleteInstance', payload)
// }

export const deleteInstanceApi = (id) => {
  return deleteApi(`/instance/deleteInstance?id=${id}`);
};

export const updateInstanceApi = (payload) => {
  return putInstanceApi(`/instance/updateInstance`, payload);
};

//All results api
export const getAllTestResultsApi = () => {
  return getApi(`/testResult/getAllTestResult`);
};

export const getDetailsByCandiateIdAndTestId = (candidateId, testId) => {
  return getApi(
    `/testResult/getTestResultByCandidateIdAndTestId?candidateId=${candidateId}&testId=${testId}`
  );
};

export const updateEmployer = ({ uri, payload }) => {
  return postApi(uri, payload);
};
