import { Link, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Loader } from "../../Components/common/loader";
import { getDetailsByCandiateIdAndTestId } from "../../apis";

const ViewTestDetails = () => {
  const [detailsById, setDetailsById] = useState({ data: "", loading: false });

  const params = useParams();
  const candidateId = parseInt(params?.candidateId);
  const testId = parseInt(params?.testId);
  const fetchDetailsByCandiateIdAndTestId = async (a, b) => {
    setDetailsById({ loading: true });
    try {
      const res = await getDetailsByCandiateIdAndTestId(a, b);
      if (res?.status === 200) {
        let result = res?.data?.testResults?.[0];
        result.testJSON.UserStats.MapAttributes =
          result?.testJSON?.UserStats?.MapAttributes?.map((l) => {
            const keyy = Object.keys(l)[0];
            return {
              name: keyy,
              ...l?.[keyy],
            };
          });

        setDetailsById({
          data: result,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (candidateId !== "" && testId !== "") {
      fetchDetailsByCandiateIdAndTestId(candidateId, testId);
    }
  }, [candidateId, testId]);

  return (
    <div className="container px-4 px-lg-2 p-4">
      <div className="">
        <h1 className="mb-1 h2 fw-bold">
          Test Details
          <span className="fs-5 text-muted ms-1"></span>
        </h1>
        <div className="mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to="/test-score">Test Score</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Test Score
              </li>
            </ol>
          </nav>
        </div>
        <div className="card mb-4">
          <div className="card-header"></div>
          <div className="table-responsive">
            {detailsById?.loading ? (
              <div className="text-center mt-2">
                <Loader />
              </div>
            ) : (
              <table className="table mb-0 text-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Candidate's Name
                    </th>
                    <th scope="col" className="border-0">
                      Email Id
                    </th>
                    <th scope="col" className="border-0">
                      Phone Number
                    </th>
                    <th scope="col" className="border-0">
                      Test Name
                    </th>
                    <th scope="col" className="border-0">
                      Total Tests
                    </th>
                    <th scope="col" className="border-0">
                      Total Attempted
                    </th>
                    <th scope="col" className="border-0">
                      Total Solved
                    </th>
                    <th scope="col" className="border-0">
                      Time Remaining
                    </th>
                    <th scope="col" className="border-0">
                      Start Time
                    </th>
                    <th scope="col" className="border-0">
                      End Time
                    </th>
                    {/* <th scope="col" className="border-0" /> */}
                  </tr>
                </thead>
                <tbody>
                {
                  console.log(detailsById, "__________")
                }
                  <tr id="table">
                    <td className="align-middle border-top-0">
                      {detailsById?.data?.firstName &&
                      detailsById?.data?.lastName
                        ? detailsById?.data?.firstName +
                          " " +
                          detailsById?.data?.lastName
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0">
                      {/* {detailsById?.data?.createdAt} */}
                      {detailsById?.data?.email
                        ? detailsById?.data?.email
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.phoneNumber
                        ? detailsById?.data?.phoneNumber
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.title
                        ? detailsById?.data?.title
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0">
                      {detailsById?.data?.testJSON?.UserStats?.TotalTests
                        ? detailsById?.data?.testJSON?.UserStats?.TotalTests
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0">
                      {/* {detailsById?.data?.createdAt} */}
                      {detailsById?.data?.testJSON?.UserStats?.TotalAttempted
                        ? detailsById?.data?.testJSON?.UserStats?.TotalAttempted
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.testJSON?.UserStats?.TotalSolved !==
                      ""
                        ? detailsById?.data?.testJSON?.UserStats?.TotalSolved
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.testJSON?.UserStats?.TimeRemaining
                        ?.Hour !== "" ||
                      detailsById?.data?.testJSON?.UserStats?.TimeRemaining
                        ?.Hour !== ""
                        ? detailsById?.data?.testJSON?.UserStats?.TimeRemaining
                            ?.Hour +
                          " Hr : " +
                          detailsById?.data?.testJSON?.UserStats?.TimeRemaining
                            ?.Minutes +
                          " Mins"
                        : "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.testJSON?.UserStats?.TestStartTime?.Hour + ":" +
                        detailsById?.data?.testJSON?.UserStats?.TestStartTime?.Minutes ?? "N/A"}
                    </td>
                    <td className="align-middle border-top-0 mb-0">
                      {detailsById?.data?.testJSON?.UserStats?.TestEndTime?.Hour + ":" +
                          detailsById?.data?.testJSON?.UserStats?.TestEndTime?.Minutes ?? "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>

        <h1 className="mb-1 h2 fw-bold mb-2">
          Attributes
          <span className="fs-5 text-muted ms-1">
            {/* ({users?.length || 0}) */}
          </span>
        </h1>
        {/* card */}
        <div className="card">
          {/* card header */}
          <div className="card-header"></div>
          {/* table */}
          <div className="table-responsive min-h-500px max-h-700px">
            {detailsById?.data?.testJSON !== null &&
            detailsById?.data?.testJSON?.UserStats?.MapAttributes.length > 0 ? (
              <table className="table mb-0 text-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="border-0">
                      S.No
                    </th>
                    {/* <th scope="col" className="border-0">
                            Candidate Name
                          </th> */}
                    <th scope="col" className="border-0">
                      Name
                    </th>
                    <th scope="col" className="border-0">
                      Difficulty
                    </th>
                    <th scope="col" className="border-0">
                      Status
                    </th>
                    <th scope="col" className="border-0">
                      Time Spent
                    </th>
                    <th scope="col" className="border-0">
                      Feedback
                    </th>
                    {/* <th scope="col" className="border-0" /> */}
                  </tr>
                </thead>
                <tbody>
                  {detailsById?.data?.testJSON?.UserStats?.MapAttributes?.map(
                    (el, i) => {
                      return (
                        <tr id="table" key={`page-${i + 1}`}>
                          <td className="align-middle border-top-0">
                            <div className="d-flex align-items-center">
                              {`${i + 1}`}
                            </div>
                          </td>
                          <td className="align-middle border-top-0">
                            {el?.name ? el?.name : "N/A"}
                          </td>
                          <td className="align-middle border-top-0">
                            {el?.Difficulty !== "" ? el?.Difficulty : "N/A"}
                          </td>
                          <td className="align-middle border-top-0 mb-0">
                            {el?.TestStatus ? el?.TestStatus : "N/A"}
                          </td>
                          <td className="align-middle border-top-0 mb-0">
                            {el?.TimeSpent?.Hour !== "" ||
                            el?.TimeSpent?.Minutes !== ""
                              ? el?.TimeSpent?.Hour +
                                " Hr : " +
                                el?.TimeSpent?.Minutes +
                                " Mins"
                              : "N/A"}
                          </td>
                          <td className="align-middle border-top-0 mb-0">
                            {el?.Feedback !== "" ? el?.Feedback : "N/A"}
                          </td>
                          {/* <td className="text-muted px-4 py-3 align-middle border-top-0">
                                       <span className="dropdown dropstart">
                                          <a
                                             className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                             href="#"
                                             role="button"
                                             id="courseDropdown"
                                             data-bs-toggle="dropdown"
                                             data-bs-offset="0,0"
                                             aria-expanded="false"
                                          >
                                             <i className="fe fe-more-vertical" />
                                          </a>
                                          <span
                                             className="dropdown-menu"
                                             aria-labelledby="courseDropdown"
                                          >
                                             <span className="dropdown-header">
                                                Settings
                                             </span>

                                             <a
                                                href={`/view-test-details/${el?.candidateId}/${el?.testId}`}
                                                className="dropdown-item"
                                             >
                                                <i className="bi bi-hand-index dropdown-item-icon" />
                                                View
                                             </a>
                                          </span>
                                       </span>
                                    </td> */}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            ) : detailsById?.loading ? (
              <div className="text-center mt-4">
                <Loader />
              </div>
            ) : (
              <div className="text-center p-4">No data found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTestDetails;
