import React, { useContext, useEffect, useState } from "react";
import {
  deleteTestApi,
  getAllCandidatesAppliedApi,
  updateCandidateStatusApi,
} from "../../apis";

import AppContext from "../../appContext";
import DropDown from "./DropDown";
import { Link } from "react-router-dom";
import { Loader } from "../../Components/common/loader";
import { confirm } from "react-confirm-box";
import { getApi } from "../../apis/api-interface";
import { toast } from "react-toastify";

// import CandidateDetails from './CandidateDetails';

const CandidatesApplied = () => {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  const [filter, setFilter] = useState("all");
  const [candidatesList, setCandidatesList] = useState({
    search: "",
    data: null,
    loading: false,
  });

  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });
  const fetchUserCounts = async () => {
    const response = await getApi("/users/count");
    setDashboardData((v) => ({
      ...v,
      loading: false,
      data: response.data,
    }));
  };

  useEffect(() => {
    fetchUserCounts();
  }, []);

  const fetchAppliedCandidatesList = async (search) => {
    try {
      setCandidatesList((v) => ({
        ...v,
        loading: true,
        data: null,
      }));

      const res = await getAllCandidatesAppliedApi(search);

      if (res.status == 200) {
        setCandidatesList((v) => ({
          ...v,
          loading: false,
          data: res.data.data[0],
        }));
      } else {
        setCandidatesList((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setCandidatesList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchAppliedCandidatesList(candidatesList.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesList.search]);

  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  const handleDelete = async (val) => {
    const result = await confirm("Are you sure ?", options);
    if (result) {
      const del = await deleteTestApi({ id: val.id, isActive: false });
      // console.log(del);
      await fetchAppliedCandidatesList("");
    } else {
      return false;
    }
  };
  const updateStatus = async (id, email, title, status) => {
    try {
      const response = await updateCandidateStatusApi({
        id: id,
        status: status,
        testName: title,
        email,
      });
      if (response?.status == 200) {
        await fetchAppliedCandidatesList("");
      } else {
        toast.error("Something went wrong");
      }
      // console.log(response);
    } catch (err) {
      toast.error("Something went wrong");
    }
  };
  const filterCandidate = candidatesList?.data?.filter(({ status }) => {
    if (filter === undefined || filter === "all") {
      return true;
    } else {
      return status === filter;
    }
  });

  return (
    <>
      <div className="container px-4 px-lg-2 p-4">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Applied Candidates
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Applied Candidates
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {/* <div className="mb-3">
          <Link to='/test/create-test'>

          <button className="btn btn-primary btn-lg">Create Test</button>
          </Link>
        </div> */}
        {candidatesList.loading ? (
          <div className="text-center p-4">
            <Loader />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row userTableInfo ">
                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Applied Candidates
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-users fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {dashboardData?.data ? (
                          dashboardData?.data?.totalAppliedCandidates
                        ) : (
                          <Loader />
                        )}
                        {/* {candidatesList?.data?.length} */}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Approved Candidates
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-user-check fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {dashboardData?.data ? (
                          dashboardData?.data?.totalApprovedCandidates
                        ) : (
                          <Loader />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Rejected Candidates
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-user-x fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {dashboardData?.data ? (
                          dashboardData?.data?.totalRejectedCandidates
                        ) : (
                          <Loader />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                        <div>
                          <span className="fs-6 text-uppercase fw-semi-bold">
                            Total Pending Candidates
                          </span>
                        </div>
                        <div>
                          <span className=" fe fe-user fs-3 text-primary" />
                        </div>
                      </div>
                      <h2 className="fw-bold mb-1">
                        {dashboardData?.data ? (
                          dashboardData?.data?.totalPendingCandidates
                        ) : (
                          <Loader />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab pane */}
              <div className="">
                {/* card */}
                <div className="card">
                  {/* card header */}
                  <div className="card-header">
                    {/* <input
                      type="search"
                      className="form-control"
                      value={candidatesList.search}
                      onChange={handleSearch}
                      placeholder="Search user by email"
                    /> */}
                    <DropDown setFilter={setFilter} />
                  </div>
                  {/* table */}
                  <div className="table-responsive min-h-500px">
                    {candidatesList?.data?.length ? (
                      <table className="table mb-0 text-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className="border-0">
                              S.No
                            </th>
                            {/* <th scope="col" className="border-0">
                            Candidate Name
                          </th> */}
                            <th scope="col" className="border-0">
                              Registered Email
                            </th>
                            <th scope="col" className="border-0">
                              Test Name
                            </th>
                            <th scope="col" className="border-0">
                              Active
                            </th>
                            <th scope="col" className="border-0">
                              Status
                            </th>

                            <th scope="col" className="border-0">
                              Set Status
                            </th>
                            {/* <th scope="col" className="border-0" /> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filterCandidate?.map((el, i) => {
                            return (
                              <tr id="table" key={`page-${i + 1}`}>
                                <td className="align-middle border-top-0">
                                  <div className="d-flex align-items-center">
                                    {`${i + 1}`}
                                  </div>
                                </td>

                                <td className="align-middle border-top-0">
                                  {el?.email}
                                </td>
                                <td className="align-middle border-top-0">
                                  {/* {el?.createdAt} */}
                                  {el?.testTitle}
                                </td>
                                <td className="align-middle border-top-0 mb-0">
                                  {el.isActive == true ? (
                                    <p className="green_flag"> Active</p>
                                  ) : (
                                    <p className="red_flag"> Inactive</p>
                                  )}
                                </td>
                                <td className="align-middle border-top-0 mb-0">
                                  {el.status.toLowerCase() == "approved" ? (
                                    <p className="green_flag">Approved</p>
                                  ) : el.status.toLowerCase() == "rejected" ? (
                                    <p className="red_flag">Rejected</p>
                                  ) : (
                                    <p className="yellow_flag"> pending</p>
                                  )}
                                </td>

                                <td className="text-muted px-4 py-3 align-middle border-top-0">
                                  {!el.sendEmail ? (
                                    <span className="dropdown dropstart">
                                      <a
                                        className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                        href="#"
                                        role="button"
                                        id="courseDropdown"
                                        data-bs-toggle="dropdown"
                                        data-bs-offset="0,0"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <span
                                        className="dropdown-menu"
                                        aria-labelledby="courseDropdown"
                                      >
                                        <span className="dropdown-header">
                                          Perform Action
                                        </span>
                                        <button
                                          state={el}
                                          className="dropdown-item btn"
                                          disabled={
                                            el.status == "approved"
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            updateStatus(
                                              el.id,
                                              el.email,
                                              el.testTitle,
                                              "approved"
                                            )
                                          }
                                        >
                                          <i className="fe fe-check dropdown-item-icon text-success" />
                                          Approve
                                        </button>
                                        <button
                                          state={el}
                                          disabled={
                                            el.status == "rejected"
                                              ? true
                                              : false
                                          }
                                          className="dropdown-item btn"
                                          onClick={() =>
                                            updateStatus(
                                              el.id,
                                              el.email,
                                              el.testTitle,
                                              "rejected"
                                            )
                                          }
                                        >
                                          <i className="fe fe-x dropdown-item-icon text-danger" />
                                          Reject
                                        </button>
                                      </span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                {/* <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Settings
                                    </span>
                                    
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                     
                                      onClick={() => handleDelete(el)}
                                    >
                                      <i className="fe fe-trash dropdown-item-icon" />
                                      Delete
                                    </a>
                                  </span>
                                </span>
                              </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center p-4">No data found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <CandidateDetails/> */}
    </>
  );
};

export default CandidatesApplied;
