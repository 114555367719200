import * as Yup from "yup";

const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

export const validationSchema = Yup.object().shape({
  // name:Yup.string()
  // .required('Required'),
  // summary:Yup.string()
  // .required('Required'),
  // description:Yup.string()
  // .required('Required'),
  // bannerImage:Yup.string()
  // .required('Required'),
  // duration:Yup.string()
  // .required('Enter valid input'),
  // duration: Yup.number()
  // .required("Required"),
  // shortDescription:Yup.string()
  // .required('Required'),
  // numberOfTestIncludes: Yup.string()
  //     .required('Required'),
  // testIncludes: Yup.array().of(
  //     Yup.object().shape({
  //         // title: Yup.string()
  //         //     .required('Required'),
  //         description: Yup.string()
  //             .required('Description is required')
  // })
  // ),
  // testDetail: Yup.string()
  // .required('Required'),
  // whatyouwilllearn: Yup.string()
  // .required('Required'),
  // numberofDetails: Yup.string()
  // .required('Required'),
  // details: Yup.array().of(
  //     Yup.object().shape({
  //         title: Yup.string()
  //             .required('Title Required'),
  //         description: Yup.string()
  //             .required('Description Required')
  //     })
  // ),
});

export const testValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  summary: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  testDetail: Yup.array().of(Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }))
});

export const instanceValidation = Yup.object().shape({
  instance: Yup.string().required("Required"),
  url: Yup.string().matches(URL, "Please enter a valid url").required("Required"),
  userName: Yup.string().required("Required"),
  password: Yup.string().required("Password can't be empty"),
});