import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllRegisteredUsersApi,
} from "../../apis";
import AppContext from "../../appContext";
import { Loader } from "../../Components/common/loader";
import CandidateDetails from "../Profile Review/CandidateDetails";
import { getApi } from "../../apis/api-interface";

const RegisteredUsers = () => {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  const [candidateList, setCandidateList] = useState({
    search: "",
    data: null,
    loading: false,
  });
  console.log(candidateList)
  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
  });

  const [selectedUser, setSelectedUsers] = useState({});

  const fetchUserCounts = async () => {
    const response = await getApi("/users/count");
    setDashboardData((v) => ({
      ...v,
      loading: false,
      data: response.data,
    }));
    console.log(response.data, "11111111111111111111111111111111");
    console.log(dashboardData?.data?.totalActiveCandidates, "2222222222222222222222");
  };

  useEffect(() => {
    fetchUserCounts()
  }, [])

  const fetchcandidateList = async (search) => {
    try {
      setCandidateList((v) => ({
        ...v,
        loading: true,
        data: null,
      }));
      const res = await getAllRegisteredUsersApi(search);
      // const res1 = await getDashboardDataApi(search);

      if (res.status == 200) {
        setCandidateList((v) => ({
          ...v,
          loading: false,
          data: res.data.data,
        }));
        console.log(candidateList.data, "1111111111111111111111111111111");
      } else {
        setCandidateList((v) => ({
          ...v,
          loading: false,
        }));
        toast.error("Something went wrong.", { toastId: "err01" });
      }

      // if (res1.status == 200) {
      //   setDashboardData((v) => ({
      //     ...v,
      //     loading: false,
      //     data: res1.data.data,
      //   }));
      // } else {
      //   setDashboardData((v) => ({
      //     ...v,
      //     loading: false,
      //   }));
      //   toast.error("Something went wrong.", { toastId: "err01" });
      // }
    } catch (error) {
      if (error.response.status == 401) {
        logout();
      }
      const message =
        error.response?.data?.message || error.response.statusText;
      toast.error(message, { toastId: "err01" });
      setCandidateList((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchcandidateList(candidateList.search);
  }, [candidateList.search]);

  // const handleSearch = (el) => {
  //   setCandidateList((v) => ({
  //     ...v,
  //     search: el.target.value,
  //   }));
  // };

  // useEffect(() => {
  //   // showeForm(selectedUser);
  // }, [selectedUser]);

  // const handleDelete = (val) => {

  // };

  // const handleDelete = (val) => {
  //   const confirm = window.confirm("Please confirm your action!!");

  //   if (confirm) {
  //     deleteUserApi(val);
  //   } else {
  //     return false;
  //   }

  //   fetchcandidateList("");
  // };

  // const options = {
  //   labels: {
  //     confirmable: "Confirm",
  //     cancellable: "Cancel",
  //   },
  // };

  // const handleDelete = async (val) => {
  //   const result = await confirm("Are you sure ?", options);
  //   if (result) {
  //     deleteUserApi(val);
  //     fetchcandidateList("");
  //   } else {
  //     return false;
  //   }
  // };

  // const handleSuspend = async (val) => {
  //   const result = await confirm("Are you sure ?", options);
  //   if (result) {
  //     suspendUserApi(val);
  //     fetchcandidateList("");
  //   } else {
  //     return false;
  //   }
  // };

  // const handleActivate = async (val) => {
  //   // const result = await confirm("Are you sure ?", options);
  //   // if (result) {
  //   activateUserApi(val);
  //   fetchcandidateList("");
  //   // } else {
  //   //   return false;
  //   // }
  // };

  // const users = candidateList.data;

  // const handleStatus = () => {
  //   if (
  //     candidateList?.data[0]?.isActive == true &&
  //     candidateList?.data[0]?.isSuspended == false
  //   ) {
  //     return <p className="green_flag">Active</p>;
  //   } else {
  //     return <p className="red_flag">Suspended</p>;
  //   }
  // };

  // const handleEmailSplit = () => {
  //   var a = el.split("@");
  //   console.log(a);
  // };

  const handleView = (el) => {
    setSelectedUsers(el);
    console.log(el);
  };

  return (
    <>
      <div className="container px-4 px-lg-2 p-4 ">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Registered Users
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Registered Users
                    </li>
                  </ol>
                </nav>
              </div>
              <div></div>
            </div>
          </div>
        </div>
       {candidateList.loading ? (
                    <div className="text-center p-4">
                      <Loader />
                    </div>
                  ) : <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="row userTableInfo ">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Registered Users
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-users fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {candidateList?.data?.length || 0}
                    </h2>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Active Candidates
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.activeUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semi-bold">
                          Total Inactive Candidates
                        </span>
                      </div>
                      <div>
                        <span className=" fe fe-user-x fs-3 text-primary" />
                      </div>
                    </div>
                    <h2 className="fw-bold mb-1">
                      {dashboardData?.data?.deactiveUsersCount || 0}
                    </h2>
                  </div>
                </div>
              </div> */}
            </div>
            {/* tab pane */}
            <div className="">
              {/* card */}
              <div className="card">
                {/* card header */}
                {/* <div className="card-header">
                  <input
                    type="search"
                    className="form-control"
                    value={candidateList.search}
                    onChange={handleSearch}
                    placeholder="Search user by email"
                  />
                </div> */}
                {/* table */}
                <div className="table-responsive min-h-500px ">
                  { candidateList?.data?.length ? (
                    <table className="table mb-0 text-nowrap ">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className="border-0 ">
                            S.NO
                          </th>
                          <th scope="col" className="border-0">
                            First Name
                          </th>
                          <th scope="col" className="border-0">
                            Last Name
                          </th>
                          <th scope="col" className="border-0">
                            Email
                          </th>
                          <th scope="col" className="border-0">
                            Status
                          </th>
                          
                          {/* <th scope="col" className="border-0">
                            Phone Number
                          </th>
                          <th scope="col" className="border-0">
                            Active
                          </th>
                          <th scope="col" className="border-0">
                            Time Zone
                          </th> */}

                          {/* <th scope="col" className="border-0" /> */}
                        </tr>
                      </thead>
                      <tbody>
                        {candidateList?.data?.map((el, i) => {
                          return (
                            <tr id="table" key={`page-${i + 1}`}>
                              <td className="align-middle border-top-0 ">
                                <div className="d-flex align-items-center">
                                  {/* <h5 className="mb-0"> */}

                                  {`${i + 1}`}
                                  {/* </h5> */}
                                </div>
                              </td>
                              <td className="align-middle border-top-0">
                                {el?.accountDetails[0]?.firstName}

                              </td>
                              <td className="align-middle border-top-0">

                                {el?.accountDetails[0]?.lastName}
                              </td>

                              <td className="align-middle border-top-0">
                                {el?.email}
                              </td>
                              <td className="align-middle border-top-0 mb-0">
                                {el.isProfile.toLowerCase() == "approval" ? (
                                  <p className="green_flag">Approved</p>
                                ) : el.isProfile.toLowerCase() == "rejected" ? (
                                  <p className="red_flag">Rejected</p>
                                ) : (
                                  <p className="yellow_flag">Incomplete</p>
                                )}
                              </td>

                              {/* <td className="align-middle border-top-0">
                                {/* {el?.createdAt} */}
                              {/* {el?.accountDetails[0]?.phone}
                              </td> */}

                              {/* <td className="align-middle border-top-0">
                                {el.isActive == true ? (
                                  <p className="green_flag"> Active</p>
                                ) : (
                                  <p className="red_flag"> Inactive</p>
                                )}
                              </td> */}

                              {/* <td className="align-middle border-top-0">
                                {/* {el?.createdAt} */}
                              {/* {el?.timeZone}
                              </td> */}

                              {/* <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Perform Action
                                    </span>
                                    <a
                                      href=""
                                      className="dropdown-item"
                                      data-bs-toggle="modal"
                                      data-bs-target="#candidateDetail  "
                                      onClick={() => handleView(el)}
                                    >
                                      <i className="fe fe-user dropdown-item-icon" />
                                      View
                                    </a>
                                  </span>
                                </span>
                              </td> */}
                              {/* <td className="text-muted px-4 py-3 align-middle border-top-0">
                                <span className="dropdown dropstart">
                                  <a
                                    className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                    href="#"
                                    role="button"
                                    id="courseDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="0,0"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical" />
                                  </a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="courseDropdown"
                                  >
                                    <span className="dropdown-header">
                                      Settings
                                    </span>
                                    
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                     
                                      onClick={() => handleDelete(el)}
                                    >
                                      <i className="fe fe-trash dropdown-item-icon" />
                                      Delete
                                    </a>
                                  </span>
                                </span>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center p-4">No data found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>

      {/* <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        style={{ width: 600 }}
      >
      
      </div>
      */}
      {selectedUser &&
        selectedUser.accountDetails &&
        selectedUser.accountDetails[0] && (
          <CandidateDetails el={selectedUser} />
        )}
    </>
  );
}

export default RegisteredUsers