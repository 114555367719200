import "./dropdown.scss";

import { Dropdown, DropdownButton } from "react-bootstrap";
import React, { useEffect, useState } from "react";

function DropDown({
  options = ["pending", "approved", "rejected"],
  setFilter = () => {},
}) {
  const [state, setState] = useState();
  const onClick = (value) => {
    setState(value);
  };
  useEffect(() => {
    setFilter(state);
  }, [state]);
  return (
    <DropdownButton
      id="dropdown-item-button"
      title={state ?? "Select Items"}
      className="custom-dropdown"
      variant="outline-primary"
    >
      <Dropdown.Item
        as="button"
        onClick={() => {
          onClick("all");
        }}
      >
        All
      </Dropdown.Item>
      {options?.map((option) => (
        <Dropdown.Item
          as="button"
          onClick={() => {
            onClick(option);
          }}
        >
          {option}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default DropDown;
