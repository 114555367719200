import React from 'react'
import { Loader } from '../../Components/common/loader';

const ViewPage = () => {
    const htmlData='<p>hello</p>'
    return (
        <div>
        {
          !htmlData?
          <Loader style={{margin: "auto",
            padding: "10px"}}/>
          :
          <div
          dangerouslySetInnerHTML={{__html: htmlData}}
          />
        }
  
       </div>
      );
}

export default ViewPage
