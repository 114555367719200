import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { createInstanceApi, updateInstanceApi } from '../../apis';
import { instanceValidation } from '../Test/validation';

const InstanceForm = (props) => {

   const location = useLocation()

   console.log(location?.state, "LLLLLLLLLL")

   const navigate = useNavigate()

   const {
      // initialValues,
      htmlValue,
      setHtmlValue,
      type,
      el,
      testDetails,
      setTestDetails,
      initial,
      setInitial,
      duration,
      setDuration,
   } = props;

   const formik = useFormik({
      initialValues: {
         instance: "",
         url: "",
         userName: "",
         password: ""
      },
      validationSchema: instanceValidation,
      onSubmit: async (values) => {
         try {
            if (location?.state) {
               const res = await updateInstanceApi({ ...values, id: location?.state?.id })
               if (res.status === 201 || res.status === 200) {
                  // console.log(res, "klkl")
                  toast.success("Instance updated successfully", { toastId: "001" })
                  navigate("/instances")
               }
               else {
                  toast.error("Unable to update instance")
               }
            } else {
               const res = await createInstanceApi(values)
               if (res.status === 201 || res.status === 200) {
                  // console.log(res, "klkl")
                  toast.success(res?.data?.message, { toastId: "001" })
                  navigate("/instances")
               }
            }
         } catch (error) {
            toast.error("Unable to create instance")
         }
      }
   })

   console.log(formik.values)

   let handleChange = (name, value) => {
      formik.setFieldValue(name, value)
   };

   useEffect(() => {
      if (location?.state) {
         formik.setValues({
            instance: location?.state?.instance,
            url: location?.state?.url,
            userName: location?.state?.userName,
            // password: ""
            password: location?.state?.password

         })
      }
   }, [location?.state])


   return (
      <div className="container p-4">
         <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
               <div className="border-bottom pb-4 mb-4">
                  <div className="mb-2 mb-lg-0">
                     <h1 className="mb-1 h2 fw-bold">{type} Instance</h1>
                     {/* Breadcrumb */}
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item">
                              <Link to="/">Dashboard</Link>
                           </li>
                           <li className="breadcrumb-item">
                              <Link to="/instances">Instances</Link>
                           </li>
                           <li className="breadcrumb-item active" aria-current="page">
                              {type} Create Instance
                           </li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
         {/* Row */}
         <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
               {/* Card */}
               <div className="card mb-4">
                  {/* Card header */}
                  <div className="card-header">
                     <h4 className="mb-0">Create</h4>
                  </div>
                  {/* Card body */}
                  <div className="card-body">
                     {/* Form */}

                     <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3 mb-4">
                           <label>Name of the Instance</label>
                           <input
                              name={`instance`}
                              value={formik.values.instance}
                              maxLength={50}
                              type="text"
                              className="form-control"
                              onChange={(e) => handleChange('instance', e.target.value)}
                           />

                           {Boolean(formik.touched.instance) && Boolean(formik.errors.instance) && <span className="text-danger  start-0 top-100">
                              {formik.errors.instance}
                           </span>}

                           {/* <ErrorMessage name={`name`} component="div" className="text-danger" /> */}
                           {/* {errorMsg.title?
                          <div className="text-danger">{errorMsg.title}</div>:""}  */}
                        </div>

                        <div className="mb-3 mb-4">
                           <label>URL</label>
                           <input
                              name={`url`}
                              value={formik.values.url}
                              type="text"
                              className={"form-control"}
                              onChange={(e) => handleChange('url', e.target.value)}
                           />
                           {Boolean(formik.touched?.url) && Boolean(formik.errors?.url) && <span className="text-danger  start-0 top-100">
                              {formik.errors?.url}
                           </span>}
                           {/* <ErrorMessage name={`summary`} component="div" className="text-danger" /> */}
                           {/* {errorMsg.summary?
                          <div className="text-danger">{errorMsg.summary}</div>:''} */}
                        </div>

                        <div className="mb-3 mb-4">
                           <label>User Name</label>
                           <input
                              name={`userName`}
                              value={formik.values.userName}
                              type="text"
                              className={"form-control"}
                              onChange={(e) => handleChange('userName', e.target.value)}
                           />
                           {Boolean(formik.touched?.userName) && Boolean(formik.errors?.userName) && <span className="text-danger  start-0 top-100">
                              {formik.errors?.userName}
                           </span>}
                           {/* <ErrorMessage name={`summary`} component="div" className="text-danger" /> */}
                           {/* {errorMsg.summary?
                          <div className="text-danger">{errorMsg.summary}</div>:''} */}
                        </div>

                        <div className="mb-3 mb-4">
                           <label>Password</label>
                           <input
                              name={`password`}
                              value={formik.values.password}
                              type="text"
                              className={"form-control"}
                              onChange={(e) => handleChange('password', e.target.value)}
                           />
                           {Boolean(formik.touched?.password) && Boolean(formik.errors?.password) && <span className="text-danger  start-0 top-100">
                              {formik.errors?.password}
                           </span>}
                           {/* <ErrorMessage name={`summary`} component="div" className="text-danger" /> */}
                           {/* {errorMsg.summary?
                          <div className="text-danger">{errorMsg.summary}</div>:''} */}
                        </div>
                        <button className="btn btn-primary " type="submit">
                           Save
                        </button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default InstanceForm