// -----------------------     Server URL      -----------------
export const REACT_APP_API_BASE_URL = "https://api.visceral.io";
export const REACT_APP_BUCKET_URL =
  "https://visceral-assets.s3.ap-south-1.amazonaws.com/";
// export const REACT_APP_API_BASE_URL = "https://stage-api.visceral.io";

// -------------------     Local URL       --------------------
// export const REACT_APP_API_BASE_URL = "http://192.168.1.200:5000";

// -------------------    local -----------------------
// export const process.env.REACT_APP_API_BASE_URL = "http://localhost:5000";

// export const REACT_APP_API_BASE_URL = "http://192.168.1.185:5000"
