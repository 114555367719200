import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createTestApi, updateTestApi, uploadBannerImageApi } from "../../apis";
import { useFormik } from "formik";
import { REACT_APP_API_BASE_URL } from "../../../src/utils/baseurl"
import { validationSchema, testValidationSchema } from "./validation";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { Loader } from "../../Components/common/loader";
import { postApi } from "../../apis/api-interface";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEditor } from "ckeditor4-react";
import { encodeURI2 } from "../../utils/helperFunctions";

const TestIndex = (props) => {
  const {
    // initialValues,
    htmlValue,
    setHtmlValue,
    type,
    el,
    testDetails,
    setTestDetails,
    initial,
    setInitial,
    duration,
    setDuration,
  } = props;
  // console.log(props.el.bannerImage, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      summary: "",
      description: "",
      bannerImage: "",
      testDetail: [
        {
          title: "",
          description: "",
        },
      ]
    },
    validationSchema: testValidationSchema,
    onSubmit: async values => {
      try {

        let response = "";

        if (type == "Create") {
          response = await createTestApi({
            title: formik.values.name,
            description: formik.values.description,
            summary: formik.values.summary,
            duration: duration,
            testDetails: formik.values.testDetail,
            isCompleted: false,
            isDuration: isDuration,
            isInstructions: isInstructions,
            isCertificateOnCompleteion: isCertificateOnCompleteion,
            isConvenienceAtYourEnd: isConvenienceAtYourEnd,
            isSupport: isSupport,
            isActive: true,
            bannerImage: image,
          });

          if (response?.data?.status) {
            setLoading(false);
            toast.success("Test Created Successfully");
            navigate("/test");
          } else {
            toast.error("Something went wrong!! ");
            setLoading(false);
          }
        } else {
          response = await updateTestApi({
            id: el.id,
            title: formik.values.name,
            description: formik.values.description,
            summary: formik.values.summary,
            duration: `${isDuration ? duration : "0"}`,
            testDetails: formik.values.testDetail,
            isCompleted: false,
            isDuration: isDuration,
            isInstructions: isInstructions,
            isCertificateOnCompleteion: isCertificateOnCompleteion,
            isConvenienceAtYourEnd: isConvenienceAtYourEnd,
            isSupport: isSupport,
            isActive: true,
            bannerImage: image,
          });
          if (response.status == 200) {
            setLoading(false);
            navigate("/test");
          } else {
            toast.error("Something went wrong!! ");
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        toast.error("Something went wrong!! ");
      }
    }
  });

  // formik.setValues("sdfsa", 123)

  // console.log(formik.values.testDetail[0].title, "5555555555555555555555555555555555555")


  const [learings, setLearnings] = useState({
    isCertificateOnCompleteion: false,
    isConvenienceAtYourEnd: false,
    isDuration: false,
    isInstructions: false,
    isSupport: false,
  });
  const [errorMsg, setErrMsg] = useState({
    title: "",
    summary: "",
    description: "",
  });

  const [isCertificateOnCompleteion, setIsCertificateOnCompleteion] =
    useState(false);
  const [isConvenienceAtYourEnd, setIsConvenienceAtYourEnd] = useState(false);
  const [isDuration, setIsDuration] = useState(false);
  const [isInstructions, setIsInstructions] = useState(false);
  const [isSupport, setIsSupport] = useState(false);

  const [image, setImage] = useState("");

  const [loading, setLoading] = useState(false);

  const [bannerData, setBannerData] = useState("");

  useLayoutEffect(() => {
    // toSetTheValue()
    if (type !== "Create") {
      setIsCertificateOnCompleteion(el.isCertificateOnCompleteion);
      setIsConvenienceAtYourEnd(el.isConvenienceAtYourEnd);
      setIsDuration(el.isDuration);
      setIsInstructions(el.isInstructions);
      setIsSupport(el.isSupport);
      setImage(el.bannerImage);
    }
  }, [el]);

  let navigate = useNavigate();

  const onInitialChange = (e) => {
    setInitial({
      ...initial,
      [e.target.name]: e.target.value,
    });
  };

  const onFileChange = async (e) => {

    let file = e.target.files[0];
    if (!file) {
      return
    }
    const type = file.type.split("/")[1];
    const imageType = ["jpeg", "jpg", "png"];
    const validImageType = imageType.includes(type);
    if (!validImageType) {
      formik.setErrors({ bannerImage: "upload a valid image : jpeg, png, jpg" });
      return;
    } else {
      if (file.size > 1000001) {
        formik.setErrors({ bannerImage: "image size is more than 1 MB" })

        return;
      } else {
        const formdata = new FormData();
        formdata.append("file", file);
        const response = await uploadBannerImageApi(formdata);
        const data = response.data;


        setImage(data.Image);
        //to set the banner image in the next div
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setBannerData(fr.result);
        };
        formik.setErrors({});
      }

      formik.setErrors({});
    }

  };

  // const onSubmit = async (e, fields) => {
  //   e.preventDefault();

  //   // if(!initial.title){
  //   //   setErrMsg({
  //   //     ...errorMsg,
  //   //     ["title"]:"Required"
  //   //   })
  //   // }
  //   // if(!initial.summary){
  //   //   setErrMsg({
  //   //     ...errorMsg,
  //   //     ["summary"]:"Required"
  //   //   })
  //   // }
  //   // if(!htmlValue){
  //   //   setErrMsg({
  //   //     ...errorMsg,
  //   //     ["description"]:"Required"
  //   //   })
  //   // }
  //   try {
  //     console.log(initial, htmlValue, image);

  //     let response = "";

  //     if (type == "Create") {
  //       response = await createTestApi({
  //         title: initial.name,
  //         description: htmlValue,
  //         summary: initial.summary,
  //         duration: duration,
  //         testDetails: testDetails,
  //         isCompleted: false,
  //         isDuration: isDuration,
  //         isInstructions: isInstructions,
  //         isCertificateOnCompleteion: isCertificateOnCompleteion,
  //         isConvenienceAtYourEnd: isConvenienceAtYourEnd,
  //         isSupport: isSupport,
  //         isActive: true,
  //         bannerImage: image,
  //       });

  //       if (response?.data?.status) {
  //         setLoading(false);
  //         toast.success("Test Created Successfully");
  //         navigate("/test");
  //       } else {
  //         toast.error("Something went wrong!! ");
  //         setLoading(false);
  //       }
  //     } else {
  //       response = await updateTestApi({
  //         id: el.id,
  //         title: initial.name,
  //         description: htmlValue,
  //         summary: initial.summary,
  //         duration: `${isDuration ? duration : "0"}`,
  //         testDetails: testDetails,
  //         isCompleted: false,
  //         isDuration: isDuration,
  //         isInstructions: isInstructions,
  //         isCertificateOnCompleteion: isCertificateOnCompleteion,
  //         isConvenienceAtYourEnd: isConvenienceAtYourEnd,
  //         isSupport: isSupport,
  //         isActive: true,
  //         bannerImage: image,
  //       });
  //       console.log(response);
  //       if (response.status == 200) {
  //         setLoading(false);
  //         navigate("/test");
  //       } else {
  //         toast.error("Something went wrong!! ");
  //         setLoading(false);
  //       }
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     toast.error("Something went wrong!! ");
  //   }
  // };

  let handleChange = (name, value) => {
    formik.setFieldValue(name, value)
  };

  let addFormFields = () => {
    formik.setFieldValue('testDetail', [...formik.values.testDetail, { title: "", description: "" }])
  };

  let removeFormFields = (i) => {
    // let newtestDetails = [...testDetails];
    // newtestDetails.splice(i, 1);
    // setTestDetails(newtestDetails);
    let newdata = formik.values.testDetail?.filter((item, index) => index !== i)
    formik.setFieldValue('testDetail', newdata)
  };

  const rteChange = (evt) => {
    // console.log( evt.editor.getData())
    let data = evt.editor.getData();
    formik.setFieldValue('description', data)

  };


  useEffect(() => {
    // console.log(props?.testDetails,'props?.testDetails')
    if (el) {
      formik.setFieldValue("name", el?.title)
      formik.setFieldValue("summary", el?.summary)
      formik.setFieldValue("description", el?.description)
      formik.setFieldValue("bannerImage", el?.bannerImage)
    }
    if (testDetails) {
      formik.setFieldValue('testDetail', testDetails)
    }
  }, [el, testDetails])
  // to set  the value of formik
  // const toSetTheValue = () => {

  // }
  // useLayoutEffect(() => {
  //   toSetTheValue()
  // }, [])
  // console.log(formik, "1111111111111111111111111111111");
  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="border-bottom pb-4 mb-4">
            <div className="mb-2 mb-lg-0">
              <h1 className="mb-1 h2 fw-bold">{type} Test</h1>
              {/* Breadcrumb */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {type} Test
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Row */}
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            {/* Card header */}
            <div className="card-header">
              <h4 className="mb-0">Create</h4>
            </div>
            {/* Card body */}
            <div className="card-body">
              {/* Form */}

              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3 mb-4">
                  <label>Test Name</label>
                  <input
                    name={`name`}
                    value={formik.values.name}

                    maxLength={50}
                    type="text"
                    className="form-control"
                    onChange={(e) => handleChange('name', e.target.value)}
                  // onChange={e => formik.setFieldValue("name", e.target.value)}
                  />

                  {Boolean(formik.touched.name) && Boolean(formik.errors.name) && <span className="text-danger  start-0 top-100">
                    {formik.errors.name}
                  </span>}

                  {/* <ErrorMessage name={`name`} component="div" className="text-danger" /> */}
                  {/* {errorMsg.title?
                          <div className="text-danger">{errorMsg.title}</div>:""}  */}
                </div>

                <div className="mb-3 mb-4">
                  <label>Test Summary</label>
                  <input
                    name={`summary`}
                    value={formik.values.summary}
                    type="text"
                    className={"form-control"}
                    onChange={(e) => handleChange('summary', e.target.value)}
                  />
                  {Boolean(formik.touched?.summary) && Boolean(formik.errors?.summary) && <span className="text-danger  start-0 top-100">
                    {formik.errors?.summary}
                  </span>}
                  {/* <ErrorMessage name={`summary`} component="div" className="text-danger" /> */}
                  {/* {errorMsg.summary?
                          <div className="text-danger">{errorMsg.summary}</div>:''} */}
                </div>

                <div className="mb-3 mb-4">
                  <label>
                    Banner Image
                    <small> (optional: should be less than 1 MB)</small>
                  </label>
                  <input
                    id="bannerImage"
                    name={`bannerImage`}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="form-control"
                    onChange={onFileChange}
                    hidden={true}
                  />

                  {/* <ErrorMessage name={`bannerImage`} component="div" className="text-danger" /> */}
                </div>
                <div style={{ width: "100px", height: "100px" }}>
                  <label
                    className=""
                    htmlFor="bannerImage"
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "0.3px solid #191313",
                      backgroundSize: "cover",
                      cursor: "pointer",
                      borderRadius: "10px",
                      backgroundImage: `${bannerData
                        ? `url(${bannerData})`
                        : el?.bannerImage
                          ? "url(" +
                          encodeURI2(el?.bannerImage) +
                          ")"
                          : "url(/placeholder.jpg)"
                        }`,
                    }}
                  ></label>
                </div>
                <div className="text-danger  start-0 top-100">{formik.errors.bannerImage}</div>
                <div className="mb-3 mb-4">
                  <label>Test Description</label>
                  {/* <JoditEditor name={`description`} value={htmlValue} onChange={content=>{setHtmlValue(content)}}/> */}
                  {/* <ErrorMessage name={`description`} component="div" className="text-danger" /> */}
                  {/* {errorMsg.description?<div className="text-danger">{errorMsg.description}</div>:''} */}
                  {htmlValue !== undefined ? (
                    <CKEditor
                      name={`description`}
                      initData={formik.values.description}
                      onChange={rteChange}
                    />
                  ) : (
                    ""
                  )}
                  {Boolean(formik.touched?.description) && Boolean(formik.errors?.description) && <span className="text-danger  start-0 top-100">
                    {formik.errors?.description}
                  </span>}


                  {/* <div className="text-danger  start-0 top-100">
                    {formik.touched.description&&formik.errors.description}
                  </div> */}
                </div>

                <div className="mb-3 mb-4 ">
                  <label>This Test Includes</label>
                  <div className="row">
                    <div className="col-4 text-start"></div>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      defaultChecked={el.isDuration}
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onChange={() => setIsDuration(!isDuration)}
                    />
                    <label>Time Duration</label>
                    <div className="col-4"></div>
                    {isDuration && (
                      // <input type='text' className="form-control w-25 text-end" value={duration}  onChange={(e)=>setDuration(e.target.value)}/>

                      <div className="col-sm-2 mb-4 col-4">
                        <label htmlFor="city" className="form-label fs-base">
                          Select Time Duration
                        </label>
                        <select
                          id="city"
                          name="city"
                          className="form-select form-select-md"
                          onChange={(e) => setDuration(e.target.value)}
                          value={duration}
                        >
                          <option value="15">15 min</option>
                          <option value="60">60 min</option>
                          <option value="120">120 min</option>
                          <option value="180">180 min</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={el.isInstructions}
                      id="flexSwitchCheckDefault"
                      onChange={() => setIsInstructions(!isInstructions)}
                    />
                    <label>Set of Instructions</label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={el.isCertificateOnCompleteion}
                      id="flexSwitchCheckDefault"
                      onChange={() =>
                        setIsCertificateOnCompleteion(
                          !isCertificateOnCompleteion
                        )
                      }
                    />
                    <label>Certificate of Completion</label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={el.isConvenienceAtYourEnd}
                      id="flexSwitchCheckDefault"
                      onChange={() =>
                        setIsConvenienceAtYourEnd(!isConvenienceAtYourEnd)
                      }
                    />
                    <label>Convenience at your end</label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={el.isSupport}
                      id="flexSwitchCheckDefault"
                      onChange={() => setIsSupport(!isSupport)}
                    />
                    <label>Support</label>
                  </div>
                </div>

                <div className="mb-3 mb-4 ">
                  <div className="mb-3 mb-4 ">
                    <label>Test Details</label>
                    {console.log(formik.values, 'formik.values')}
                    {formik.values.testDetail?.map((element, index) => (

                      <div className="form-inline mb-2" key={index}>
                        <div className="row ">
                          <div className="col-5">
                            <textarea
                              type="text"
                              name={`testDetail.${index}.title`}
                              placeholder="title"
                              className="form-control"
                              value={formik?.values?.testDetail?.[index]?.title}
                              onChange={(e) => handleChange(`testDetail.${index}.title`, e.target.value)}
                            />
                            {Boolean(formik.touched?.testDetail?.[index]?.title) && Boolean(formik.errors?.testDetail?.[index]?.title) && <span className="text-danger  start-0 top-100">
                              {formik.errors?.testDetail?.[index].title}
                            </span>}
                          </div>
                          <div className="col-5">
                            <textarea
                              type="text"
                              name={`testDetail.${index}.description`}

                              placeholder="description"
                              className="form-control"
                              value={formik.values.testDetail?.[index]?.description}
                              onChange={(e) => handleChange(`testDetail.${index}.description`, e.target.value)}
                            />
                            {Boolean(formik.touched?.testDetail?.[index]?.description) && Boolean(formik.errors?.testDetail?.[index]?.description) && <span className="text-danger  start-0 top-100">
                              {formik.errors.testDetail[index].description}
                            </span>}
                          </div>
                          <div className="col-2 my-auto">
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => removeFormFields(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                    }




                    <button
                      className="btn btn-primary btn-sm mt-2"
                      type="button"
                      onClick={() => addFormFields()}
                    >
                      Add
                    </button>
                  </div>
                  {/* <div className="button-section">
              
              <button onClick={()=>alert(JSON.stringify(testDetails))}>submit</button>
          </div> */}
                </div>

                {/* <button type="submit" className="btn btn-primary">
                  Submit
                </button> */}
                <div className="">
                  {loading ? (
                    <button disabled={true} className="btn btn-primary">
                      <Loader />
                    </button>
                  ) : (
                    <button className="btn btn-primary " type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestIndex;
