import React, { useEffect, useState } from 'react';
// import JoditEditor from "jodit-react";

import 'react-quill/dist/quill.snow.css';
import { createPageApi } from '../../apis';
import { Loader } from '../../Components/common/loader';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react'


const CreatePage = () => {
    

  let navigate = useNavigate()
  const [title,setTitle] = useState('')
  const [htmlValue,setHtmlValue] = useState('')
  const [error,setError] = useState({title:"",htmlValue:""})
  const [loading,setLoading] = useState(false)


  
  const handleTitleChange = (e) =>{
    setError({
      ...error,
      ["title"]:""
    })
    setTitle(e.target.value)
  }

  
  

  const handleSubmit = () =>{
    if(title.length<1){
      setError({
        ...error,
        ["title"]:"Required"
      })
      return;
    }
    else if(title.length>30){
      setError({
        ...error,
        ["title"]:"Maximum 30 characters allowed"
      })
      return;
    }

    CreatePage();
  
  
  }

  const CreatePage = async() =>{
    try{
      setLoading(true)
      const response = await createPageApi({name: title,details:htmlValue})
      console.log(response)
      if(response?.data?.status){
        setLoading(false)
        navigate('/pages')

      }
      else{
        toast.error("Something went wrong!! ")
        setLoading(false)
      }
    }catch(err){
      setLoading(false)
      toast.error("Something went wrong!! ")
    
    }
    
  }

  const rteChange = (evt) =>{
    let data=evt.editor.getData()
    setHtmlValue(data)
  }


  return (
    <div className="container px-4 px-lg-2 p-4  mt-5">
              <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="border-bottom pb-4 mb-4 d-flex justify-content-between align-items-center">
              <div className="mb-2 mb-lg-0">
                <h1 className="mb-1 h2 fw-bold">
                  Create Page
                  <span className="fs-5 text-muted ms-1">
                    {/* ({users?.length || 0}) */}
                  </span>
                </h1>
                {/* Breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/pages">Page</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create Page
                    </li>
                  </ol>
                </nav>
              </div>
             
            </div>
          </div>
        </div>
    <div> 
    <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
          <div className="card-body">
      <label >Title<span className='text-danger'> *</span></label>
      <input type='text' className={`form-control ${error?.title!==""?"border-danger":""}`} onChange={handleTitleChange}></input>
      {error?.title!==""  && <p className='text-danger'>{error?.title}</p>}
    </div>
   
    <div className='my-5 p-4'>

    {/* <JoditEditor onChange={content=>setHtmlValue(content)}/> */}
     <CKEditor initData={htmlValue} onChange={rteChange}/>
    </div>
    <div className="p-4">
      {loading ? (
        <button disabled={true} className="btn btn-primary">
          <Loader  />
        </button>
      ) : (
        <button
          className="btn btn-primary "
          onClick={handleSubmit}
        >
          Submit
        </button>
      )}
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
);
}

export default CreatePage
