import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div>
      <header className={`header navbar navbar-expand-lg navbar-dark  border-bottom border-light shadow-sm bg-dark  navbar-sticky header_wrapper`}>
        <div className="container text-center justify-content-start">
          <Link to='/users'>
            <div className="me-5  text-light">
              Candidates
            </div>
          </Link>
          <Link to='/companies'>
            <div className="me-5  text-light">
              Companies
            </div>
          </Link>
          <Link to='/test'>
            <div className="me-5  text-light">
              Tests
            </div>
          </Link>
          <Link to='/applied-candidates'>
            <div className="me-5  text-light">
              Test Applied candidates
            </div>
          </Link>
          <Link to='/test-score'>
            <div className="me-5  text-light">
            Test Score
            </div>
          </Link>
          <Link to='/profile-review'>
            <div className="me-5  text-light">
              Review Profiles
            </div>
          </Link>
          <Link to='/pages'>
            <div className="me-5  text-light">
              Pages
            </div>
          </Link>
          <Link to='/registered-user'>
            <div className="me-5  text-light">
              Registered User
            </div>
          </Link>
          <Link to='/instances'>
            <div className="me-5  text-light">
              Instances
            </div>
          </Link>

        </div>


      </header>

    </div>
  )
}

export default Navbar
