import React from 'react'

export default function index() {
  return (
    <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center g-0 min-vh-100">
            <div className="col-lg-5 col-md-8 py-8 py-xl-0"> 
            <div className="card shadow"> 
                <div className="card-body p-6">
                <div className="mb-4">
                    <a href="../index.html">
                    <img
                        src="../assets/images/brand/logo/logo-icon.svg"
                        className="mb-4"
                        alt=""
                    />
                    </a>
                    <h1 className="mb-1 fw-bold">Forgot Password</h1>
                    <p>Fill the input to reset your password.</p>
                </div> 
                <form> 
                    <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Your Email "
                        required=""
                    />
                    </div> 
                    <div className="mb-3 d-grid">
                    <button type="submit" className="btn btn-primary">
                        Send Resest Link
                    </button>
                    </div>
                    <span>
                    Return to <a href="#">sign in</a>
                    </span>
                </form>
                </div>
            </div>
            </div>
        </div>
    </div>

  )
}
