import * as yup from 'yup';
export const signinValidationSchema = yup.object({   
    email: yup
      .string('Enter your email address')
      .email('Enter valid email address')
      .required('Email address is required'),
    password: yup
      .string('Enter your password')
      // .min(8, 'Please enter valid password')
      .required('Password is required'),
  });
  